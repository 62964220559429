import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import BrandNav from './components/tailwindui/BrandNav';
import {
  BrowserRouter as Router
} from "react-router-dom";
import Login from './components/tailwindui/Login';
import { auth } from './auth';
import { setBearerToken } from './api';
import { useAppDispatch, useAppSelector } from './hooks/useRedux';
import { resetAuthState, setAuthState } from './store/authSlice';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { NotificationContext } from './context/NotificationContext';
import { fetchSchools } from './store/schoolSlice';
import { fetchMeals } from './store/mealSlice';
import { fetchMenus } from './store/menuSlice';
import { TitleContext } from './context/TitleContext';
import Notification, { NotificationType } from './components/tailwindui/Notification';
import Maintenance from './components/tailwindui/Maintenance';

function App() {
  const maintenance = process.env.REACT_APP_MAINTENANCE ?? false;
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const authenticated = useAppSelector(state => state.auth.authenticated)
  const user = useRef<firebase.default.User | null>()
  const [notificationVisible, setNotificationVisible] = useState(false)
  const [notificationProps, setNotificationProps] = useState<{ type: NotificationType, title: string, description?: string }>({ type: 'info', title: '' })
  const notificationTimeout = useRef<NodeJS.Timeout>()
  const [title, setTitle] = useState('Love Food')

  const showNotification = (type: NotificationType, title: string, description?: string) => {
    setNotificationProps({ type, title, description })
    setNotificationVisible(true)
  }

  useEffect(() => {
    if (notificationVisible === true) {
      if (notificationTimeout.current) {
        clearTimeout(notificationTimeout.current)
      }

      notificationTimeout.current = setTimeout(() => { setNotificationVisible(false) }, 3000)
    }
  }, [notificationVisible])

  auth.onIdTokenChanged(async newUser => {
    const userUpdated = user.current?.uid !== newUser?.uid
    user.current = newUser

    const result = await newUser?.getIdTokenResult()

    if (result?.claims.type && !['admin', 'staff'].includes(result?.claims.type)) {
      auth.signOut()
      dispatch(resetAuthState())
      return
    }

    setBearerToken(result?.token)

    dispatch(setAuthState({
      authenticated: newUser !== null,
      type: result?.claims.type || '',
      schools: result?.claims.schools || [],
    }))

    // dispatch(fetchSchools())
    // dispatch(fetchMeals())
    // dispatch(fetchMenus())

    if (userUpdated) {
      if (result?.claims.type === 'admin') {
        dispatch(fetchMeals())
        dispatch(fetchMenus())
        // dispatch(fetchTerms())
      }
      dispatch(fetchSchools())
      // dispatch(fetchStudents())
    }

    setLoading(false)
  })

  return (
    <NotificationContext.Provider value={{ showNotification: showNotification }}>
      <TitleContext.Provider value={{ title, setTitle }}>
        <div className="App">
          {maintenance ? <Maintenance /> :
            loading ? <></> :
              <>
                <Router>
                  {authenticated ? <BrandNav /> : <Login />}
                </Router>
                <Notification show={notificationVisible} {...notificationProps} />
              </>
          }
        </div>
      </TitleContext.Provider>
    </NotificationContext.Provider>
  );
}

export default App;
