import { DetailedHTMLProps, InputHTMLAttributes } from "react"

export type CheckboxProps = {
  label: string
  error?: string | undefined
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export default function Checkbox({label, error, ...props}: CheckboxProps) {
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          {...props}
          type="checkbox"
          className="focus:ring-brand-green-dark h-4 w-4 text-brand-green-dark border-gray-300 rounded"
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={props.name} className="font-medium text-gray-600 capitalize">
          {label}
        </label>
      </div>
    </div>
  )
}
