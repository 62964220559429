import { format, subYears } from "date-fns";
import { useCallback, useEffect } from "react"
import { useContext, useState } from "react"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip";
import api from "../api"
import { NotificationContext } from "../context/NotificationContext";
import { TitleContext } from "../context/TitleContext"
import { useAppSelector } from "../hooks/useRedux"
import useSchoolQuery from "../hooks/useSchoolQuery"
import { schoolSelector } from "../store/schoolSlice"
import { classNames, currencyFormatter } from "../utils"
import BackButton from "./BackButton"
import Select, { SelectOption } from "./form/Select";

type Header = {
  key: string
  displayName: string
}

type Row = {
  [key: string]: string
}

export default function BillingReport() {
  const date = format(new Date(), 'yyyy')
  const prevDate = format(subYears(new Date(), 1), 'yyyy')
  const titleContext = useContext(TitleContext)
  const notificationContext = useContext(NotificationContext)
  const type = useAppSelector(state => state.auth.type)
  const schools = useAppSelector(state => state.auth.schools)
  const schoolQuery = useSchoolQuery()
  const [schoolId, setSchoolId] = useState(schoolQuery)
  const school = useAppSelector(state => schoolSelector(state, schoolId))
  const [loading, setLoading] = useState(true)
  const [headers, setHeaders] = useState<Header[]>()
  const [rows, setRows] = useState<Row[]>()
  const [selectedDate, setSelectedDate] = useState(date)

  const fetchReport = useCallback(async () => {
    if (!schoolId) return

    setLoading(true)

    try {
      const response = await api.get('/billing-report', { params: { school: schoolId, date: selectedDate } })
      setHeaders(response.data.headers)
      setRows(response.data.rows)
    } catch (error) {
      console.log('failed to load report', error)
    } finally {
      setLoading(false)
    }
  }, [schoolId, selectedDate])

  useEffect(() => { fetchReport() }, [fetchReport])

  useEffect(() => {
    let school
    if (schools.length === 0) {
      school = schoolQuery
    } else {
      school = schools[0]
    }

    setSchoolId(school)
  }, [schoolQuery, schools])

  useEffect(() => {
    titleContext.setTitle(school ? `${school.name} - Billing Report` : 'Billing Report')
  }, [titleContext, school])

  // useEffect(() => {
  //   if (school && school.id) fetchReport(school.id)
  // }, [school])

  // useEffect(() => {
  //   if (schoolId) fetchReport(schoolId)
  // }, [schoolId])

  const getRow = (row: Row, key: string, idx: number) => {
    if (idx === 0) {
      return (
        <Link
          to={{
            pathname: `/billing-report/${row[key]}`,
            search: `?school=${school?.id}&date=${selectedDate}`,
          }}
          className="text-brand-green underline hover:opacity-75 print:text-black"
        >
          {row[key]}
        </Link>
      )
    }

    if (key === 'total_cost') {
      return currencyFormatter.format(parseInt(row[key]) / 100)
    }

    return row[key]
  }

  const onDownload = async (month: string) => {
    try {
      const response = await api.post('/billing-report', { school: school?.id, month, date: selectedDate, download: true })
      window.open(response.data, 'invoice')?.focus()
    } catch (error) {
      notificationContext.showNotification('error', 'Unable to download invoice at this time.')
    }
  }

  const onInvoice = async (month: string) => {
    try {
      await api.post('/billing-report', { school: school?.id, month, date: selectedDate })
      notificationContext.showNotification('success', 'Invoice successfully sent.')
    } catch (error) {
      notificationContext.showNotification('error', 'Unable to send invoice at this time.')
    }
  }

  return (
    <div className="flex flex-col">
      <div className="mb-4 flex justify-end">
        <BackButton className="print:hidden mr-auto" />
        <div className="mr-4 w-32">
          <Select
            label=""
            options={[{ id: date, name: date }, { id: prevDate, name: prevDate }]}
            selectedId={selectedDate}
            onChange={selected => selected?.id && setSelectedDate(selected.id)}
          />
        </div>
        <button className="print:hidden brand-button flex items-center" onClick={() => { window.print() }}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
          </svg>
          Print
        </button>
      </div>
      <div className="-my-2 overflow-x-auto overflow-y-visible sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg print:rounded-none print:shadow-none print:border-none">
            <table className="min-w-full divide-y divide-gray-200 text-left">
              <thead className="bg-gray-50">
                <tr>
                  {headers?.map((header, headerIdx) => (
                    <th
                      key={headerIdx}
                      scope="col"
                      className="px-6 py-3 print:p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {header.displayName}
                    </th>
                  ))}
                  <th scope="col" className="relative px-6 py-3 print:hidden">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  rows && rows.length > 0 ? (
                    rows?.map((row, rowIdx) => (
                      <tr key={rowIdx} className={[rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50', 'print:border-b print:border-l print:border-r'].join(' ')}>
                        {
                          headers?.map((header, headerIdx) => (
                            <td key={headerIdx} className={classNames(
                              "px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500",
                              headerIdx === 0 ? 'font-medium' : undefined
                            )}>
                              {getRow(row, header.key, headerIdx)}
                            </td>
                          ))
                        }
                        <td className="px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500 print:hidden">
                          <div className="flex justify-between">
                            <button type="button" className="brand-link mr-2" onClick={() => onDownload(row['date'])}>
                              Download
                            </button>
                            {
                              type === 'admin' && (
                                <div>
                                  <button type="button" className="brand-link mr-2" data-tip data-for="invoice" onClick={() => onInvoice(row['date'])}>
                                    Send
                                  </button>
                                  <ReactTooltip id="invoice" place="bottom" effect="solid" delayShow={1000}>Email the month's invoice</ReactTooltip>
                                </div>
                              )}
                          </div>
                        </td>
                      </tr>
                    ))) : (
                    <tr className="bg-white">
                      <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {loading ? 'Loading...' : 'No Billing information available for this school.'}
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div >
  )
}
