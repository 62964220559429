import { format, fromUnixTime, getUnixTime } from "date-fns"
import { uniq } from "lodash"
import { useEffect, useState } from "react"
import api from "../api"
import { useAppSelector } from "../hooks/useRedux"
import { Booking } from "../types/booking"
import { Meal } from "../types/meal"
import { Student } from "../types/student"
import DatePicker from "./form/DatePicker"
import Select, { SelectOption } from "./form/Select"

type Row = Student & {
  booking?: Booking
  meal?: Meal
}

type TotalsRow = {
  meal: string
  meal_id: string | undefined
  count: number
  item_code: string
}

const years = [
  { id: undefined, name: 'All Years' },
  { id: 'teachers', name: 'Teacher' },
  { id: 'nursery', name: 'Nursery' },
  { id: 'reception', name: 'Reception' },
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
]

export default function ServiceReport() {
  const schools = useAppSelector(state => state.school.schools)
  const meals = useAppSelector(state => state.meal.meals)
  const [school, setSchool] = useState<string>()
  const [date, setDate] = useState<Date>(new Date())
  const [report, setReport] = useState<Row[]>()
  const [filteredReport, setFilteredReport] = useState<Row[]>()
  const [yearSearch, setYearSearch] = useState<string>()
  const [forms, setForms] = useState<SelectOption[]>([])
  const [formSearch, setFormSearch] = useState<string>()
  const [totals, setTotals] = useState<TotalsRow[]>([])

  useEffect(() => {
    const all = [{ id: undefined, name: 'All Forms' }]
    setForms(report ? [...all, ...uniq(report.map(r => r.form)).map(form => ({ id: form, name: form }))] : all)
  }, [report])

  useEffect(() => {
    setFilteredReport(
      report
        ?.filter(row => yearSearch ? row.year === yearSearch : true)
        .filter(row => formSearch ? row.form === formSearch : true)
    )
  }, [report, yearSearch, formSearch])

  useEffect(() => {
    const tempTotals: { [key: string]: TotalsRow } = {};

    (filteredReport || []).forEach(row => {
      if (row.booking && row.meal) {
        const meal = row.meal.name
        if (!tempTotals[meal]) {
          tempTotals[meal] = {
            meal,
            meal_id: row.meal.id,
            count: 0,
            item_code: row.meal.item_code,
          }
        }
        tempTotals[meal].count += 1
      }
    })

    setTotals(Object.values(tempTotals));
  }, [filteredReport])

  useEffect(() => {
    setSchool(schools.length > 0 ? schools[0].id : undefined)
  }, [schools])

  useEffect(() => {
    if (!school) return

    api
      .get('/service-report', { params: { school, date: format(date, 'yyyy-MM-dd') } })
      .then(response => {
        const report = response.data.sort((a: Row, b: Row) => {
          let yearA: string | number = a.year
          let yearB: string | number = b.year
          if (yearA === 'reception') {
            yearA = 0
          } else if (yearA === 'nursery') {
            yearA = -10
          } else if (yearA === 'teacher') {
            yearA = 999
          } else {
            yearA = parseInt(yearA)
          }

          if (yearB === 'reception') {
            yearB = 0
          } else if (yearB === 'nursery') {
            yearB = -10
          } else if (yearB === 'teacher') {
            yearB = 999
          } else {
            yearB = parseInt(yearB)
          }

          // first name, surname (alphabetical) meal, allergy, year, form
          return (a.forename === b.forename ? 0 : (a.forename < b.forename ? -1 : 1))
            || (a.surname === b.surname ? 0 : (a.surname < b.surname ? -1 : 1))
            || (a.meal!.name === b.meal!.name ? 0 : (a.meal!.name < b.meal!.name ? -1 : 1))
            || yearA - yearB
            || (a.form === b.form ? 0 : (a.form < b.form ? -1 : 1))
        })
        setReport(report)
      })
  }, [school, date])

  return (
    <>
      <div className="flex flex-col print:hidden">
        <div className="mb-4 grid gap-2 md:gap-4 md:grid-cols-3 xl:flex xl:flex-wrap xl:justify-end xl:items-end">
          <div className="w-full xl:w-64 md:col-span-2 xl:col-auto">
            <Select
              label=""
              options={schools.map(school => ({ id: school.id!, name: school.name }))}
              selectedId={school}
              onChange={selected => {
                setSchool(selected?.id)
                setFormSearch(undefined)
              }}
            />
          </div>
          <div className="w-full xl:w-48">
            <DatePicker date={getUnixTime(date)} onChange={date => date && setDate(fromUnixTime(date))} />
          </div>
          <div className="w-full xl:w-48">
            <Select
              options={years}
              selectedId={yearSearch}
              onChange={selected => setYearSearch(selected?.id)}
            />
          </div>
          <div className="w-full xl:w-48">
            <Select
              label=""
              options={forms}
              selectedId={formSearch}
              onChange={selected => setFormSearch(selected?.id)}
            />
          </div>
          <button className="print:hidden brand-button w-full xl:w-auto flex items-center" onClick={() => { window.print() }}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
            </svg>
            Print
          </button>
        </div>
        <div className="-my-2 overflow-x-auto overflow-y-visible sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg print:rounded-none print:shadow-none print:border-none">
              <table className="min-w-full divide-y divide-gray-200 text-left">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 print:p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 print:p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Meal
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 print:p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Allergies
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 print:p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Year
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 print:p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Form
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Checked</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredReport && filteredReport.length > 0 ? filteredReport.map((student, studentIdx) => (
                      <tr key={student.id} className={[studentIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50', 'print:border-b print:border-l print:border-r'].join(' ')}>
                        <td className="px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm font-medium text-gray-900">
                          {student.forename} {student.surname}
                        </td>
                        <td className={['px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500', student.meal ? '' : 'italic'].filter(Boolean).join(' ')}>
                          {student.booking ? (student.meal?.name || 'Meal Unavailable') : 'No Meal Booked'}
                        </td>
                        <td className="px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500">{[...student.allergy || [], student.dietary].join(', ')}</td>
                        <td className="px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500 capitalize">{student.year}</td>
                        <td className="px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500 capitalize">{student.form}</td>
                        <td className="px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-right text-sm font-medium border-l"></td>
                      </tr>
                    )) :
                      <tr className="bg-white">
                        <td colSpan={6} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">No Meals Booked</td>
                      </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Totals */}
        {
          totals && totals.length > 0 ?
            <>
              <h2 className="text-xl font-bold font-cursive text-brand-green-dark text-left mt-4">
                Totals
              </h2>
              <div className="mt-2 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg print:rounded-none print:shadow-none print:border-none">
                <table className="min-w-full divide-y divide-gray-200 text-left">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Code
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Booked
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {totals.map((meal, mealIdx) => (
                      <tr key={mealIdx} className={mealIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {meal.meal}
                          <span className="block text-xs font-normal text-gray-500">{meal.meal_id}</span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{meal.item_code}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{meal.count || 0}</td>
                      </tr>
                    ))}
                    <tr className={(totals.length || 0) % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Total</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">{totals.map(m => (m.count || 0)).reduce((acc, cur) => acc + cur) || 0}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </> : null
        }
        {/* End Totals */}
      </div>

      <div className="hidden print:block">
        <div className="mb-4 grid gap-2 md:gap-4 md:grid-cols-3 xl:flex xl:flex-wrap xl:justify-end xl:items-end">
          <div className="w-full xl:w-64 md:col-span-2 xl:col-auto">
            <Select
              label=""
              options={schools.map(school => ({ id: school.id!, name: school.name }))}
              selectedId={school}
              onChange={selected => {
                setSchool(selected?.id)
                setFormSearch(undefined)
              }}
            />
          </div>
          <div className="w-full xl:w-48">
            <DatePicker date={getUnixTime(date)} onChange={date => date && setDate(fromUnixTime(date))} />
          </div>
          <div className="w-full xl:w-48">
            <Select
              options={years}
              selectedId={yearSearch}
              onChange={selected => setYearSearch(selected?.id)}
            />
          </div>
          <div className="w-full xl:w-48">
            <Select
              label=""
              options={forms}
              selectedId={formSearch}
              onChange={selected => setFormSearch(selected?.id)}
            />
          </div>
        </div>
      </div>
      <div className="hidden print:block">
        {
          totals && totals.length > 0 ?
            <>
              <h2 className="text-xl font-bold font-cursive text-brand-green-dark text-left mt-4">
                Totals
              </h2>
              <div className="mt-2 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg print:rounded-none print:shadow-none print:border-none">
                <table className="min-w-full divide-y divide-gray-200 text-left">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Code
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Booked
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {totals.map((meal, mealIdx) => (
                      <tr key={mealIdx} className={mealIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {meal.meal}
                          <span className="block text-xs font-normal text-gray-500">{meal.meal_id}</span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{meal.item_code}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{meal.count || 0}</td>
                      </tr>
                    ))}
                    <tr className={(totals.length || 0) % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Total</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">{totals.map(m => (m.count || 0)).reduce((acc, cur) => acc + cur) || 0}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </> : null
        }
      </div>
      <div className="hidden print:block">
        <div className="-my-2 overflow-x-auto overflow-y-visible sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg print:rounded-none print:shadow-none print:border-none">
              <table className="min-w-full divide-y divide-gray-200 text-left">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 print:p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 print:p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Meal
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 print:p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Allergies
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 print:p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Year
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 print:p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Form
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Checked</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredReport && filteredReport.length > 0 ? filteredReport.map((student, studentIdx) => (
                      <tr key={student.id} className='print:border-b print:border-l print:border-r' style={{
                        backgroundColor: meals.find(m => m.id == student.meal?.id)?.colour as string | undefined,
                        WebkitPrintColorAdjust: 'exact',
                        colorAdjust: 'exact',
                      }}>
                        <td className="px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm font-medium text-gray-900">
                          {student.forename} {student.surname}
                        </td>
                        <td className={['px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500', student.meal ? '' : 'italic'].filter(Boolean).join(' ')}>
                          {student.booking ? (student.meal?.name || 'Meal Unavailable') : 'No Meal Booked'}
                        </td>
                        <td className="px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500">{[...student.allergy || [], student.dietary].join(', ')}</td>
                        <td className="px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500 capitalize">{student.year}</td>
                        <td className="px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500 capitalize">{student.form}</td>
                        <td className="px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-right text-sm font-medium border-l"></td>
                      </tr>
                    )) :
                      <tr className="bg-white">
                        <td colSpan={6} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">No Meals Booked</td>
                      </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
