import { Address } from "./address";
import { Contact } from "./contact";

export type School = {
  id?: string
  name: string
  schoolNumber: string
  address: Address
  contact: Contact
  billing?: SchoolBilling
  rates?: SchoolRates
}

export type SchoolBilling = {
  fsm?: boolean,
  uifsm?: boolean,
  pupil_premium?: boolean,
}
export type SchoolRates = {
  paid?: string,
  fsm?: string,
  uifsm?: string,
  pupil_premium?: string,
}

export const blankSchool: School = {
  name: '',
  schoolNumber: '',
  address: {
    line_1: '',
    line_2: '',
    city: '',
    postcode: '',
  },
  contact: {
    name: '',
    email: '',
    phone: '',
  },
  billing: {
    fsm: false,
    uifsm: false,
    pupil_premium: false,
  }
}