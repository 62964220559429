import { useContext, useEffect } from "react"
import { Link } from "react-router-dom"
import { TitleContext } from "../context/TitleContext"
import { useAppSelector } from "../hooks/useRedux"

export default function Schools() {
  const titleContext = useContext(TitleContext)
  const schools = useAppSelector(state => state.school.schools)

  useEffect(() => {
    titleContext.setTitle('Schools')
  }, [titleContext])

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="mb-4 flex justify-end items-center">
            <Link
              to={{
                pathname: `/create`,
                state: { title: 'Create School' },
              }}
              className="print:hidden brand-button flex items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
              </svg>
              Create
            </Link>
          </div>
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Number
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Address
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {schools.length > 0 ? schools.map((school, schoolIdx) => (
                  <tr key={school.id} className={schoolIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {school.name}
                      <span className="block text-xs font-normal text-gray-500">{school.id}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{school.schoolNumber}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {
                        [school.address.line_1, school.address.line_2, school.address.city, school.address.postcode]
                          .filter(s => s !== undefined && s !== null && s.length > 0)
                          .join(', ')
                      }
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-between">
                        <Link
                          to={{
                            pathname: '/students',
                            search: `?school=${school.id}`,
                            state: { title: `${school.name} - Students` },
                          }}
                          className="brand-link mr-2"
                        >
                          Students
                        </Link>
                        <Link
                          to={{
                            pathname: '/terms',
                            search: `?school=${school.id}`,
                            state: { title: `${school.name} - Terms` },
                          }}
                          className="brand-link mr-2"
                        >
                          Terms
                        </Link>
                        <Link
                          to={{
                            pathname: '/billing-report',
                            search: `?school=${school.id}`,
                          }}
                          className="brand-link mr-2"
                        >
                          Billing
                        </Link>
                        <Link
                          to={{
                            pathname: `/${school.id}`,
                            state: { title: school.name },
                          }}
                          className="brand-link"
                        >
                          Edit
                        </Link>
                      </div>
                    </td>
                  </tr>
                )) : (
                  <tr className="bg-white">
                    <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      No schools have been added, create a school to get started.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
