import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, ThunkAPI } from ".";
import api from "../api";
import { Staff } from "../types/staff";

export const ROUTE = '/staff';

type State = {
  staff: Staff[]
}

const initialState: State = {
  staff: []
}

export const fetchStaff = createAsyncThunk<Staff[], void, ThunkAPI>(
  'staff/fetch',
  async (_, thunkAPI) => {
    const response = await api.get(ROUTE)
    return response.data.data
  }
)


export const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    pushStaff: (state, action: PayloadAction<Staff>) => {
      const staffIdx = state.staff.findIndex(staff => staff.email === action.payload.email)
      if (staffIdx !== -1) {
        state.staff[staffIdx] = action.payload
      } else {
        state.staff.push(action.payload)
      }
    },
    removeStaff: (state, action: PayloadAction<Staff>) => {
      const staffIdx = state.staff.findIndex(staff => staff.email === action.payload.email)
      if (staffIdx >= 0) {
        state.staff.splice(staffIdx, 1)
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStaff.fulfilled, (state, action) => {
      state.staff = action.payload
    })
  },
})

export const staffSelector = createSelector<RootState, string | undefined, Staff[], string | undefined, Staff | undefined>(
  state => state.staff.staff,
  (_, id) => id,
  (staff, id) => id ? staff.find(staff => staff.email === id) : undefined
)

export const { pushStaff, removeStaff } = staffSlice.actions

export default staffSlice.reducer