export const firebaseConfig: { [env: string]: Object } = {
  local: {
    apiKey: "AIzaSyAkzrprasyX0YBgZPeI_aPYGFmdp-c1kIg",
    authDomain: "lovefood-80395.firebaseapp.com",
    projectId: "lovefood-80395",
    storageBucket: "lovefood-80395.appspot.com",
    messagingSenderId: "1010831546220",
    appId: "1:1010831546220:web:6fffc8764758c6625f0549",
    measurementId: "G-HWEYDG88V1"
  },
  production: {
    apiKey: "AIzaSyBy5tcmvgcfmCa2claQRNqWeFUTdcNLUWQ",
    authDomain: "lovefood-eca17.firebaseapp.com",
    projectId: "lovefood-eca17",
    storageBucket: "lovefood-eca17.appspot.com",
    messagingSenderId: "789145744807",
    appId: "1:789145744807:web:336339cd33bb7db933b282",
    measurementId: "G-EGQD1MSNYC"
  }
}