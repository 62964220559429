import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ThunkAPI } from "."
import api from "../api"
import { Booking } from "../types/booking"

const ROUTE = '/bookings'

type State = {
  bookings: Booking[]
  bookingsByWeek: {
    [school: string]: { [date: string]: Booking[] }
  }
}

const initialState: State = {
  bookings: [],
  bookingsByWeek: {},
}

export const fetchBookingsForWeek = createAsyncThunk<Booking[], { schoolId?: string, date: string, force?: boolean }, ThunkAPI>(
  'bookings/forWeek',
  async (params, _) => {
    const response = await api.get(ROUTE, { params: params })
    return response.data.data
  },
  {
    condition: ({ schoolId, date, force }, thunkAPI) => {
      if (force) return true

      const { booking } = thunkAPI.getState()

      return !schoolId
        || !booking.bookingsByWeek[schoolId]
        || !booking.bookingsByWeek[schoolId][date]
        || booking.bookingsByWeek[schoolId][date].length === 0
    }
  }
)

export const bookingSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    pushBooking: (state, action: PayloadAction<Booking>) => {
      state.bookings.push(action.payload)
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchBookingsForWeek.fulfilled, (state, action) => {
      const { schoolId, date } = action.meta.arg
      if (schoolId) {
        state.bookingsByWeek[schoolId] = {
          ...state.bookingsByWeek[schoolId],
          [date]: action.payload
        }
      }

      state.bookings = action.payload
    })
  }
})

export const { pushBooking } = bookingSlice.actions

export default bookingSlice.reducer
