import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline"
import { range } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { classNames } from "../utils"

type Props = {
  page: number
  numPages: number
  setPage: (page: number) => void
}

const PER_SIDE = 3

export default function Pagination({ page, numPages, setPage }: Props) {
  const [pageStart, setPageStart] = useState(1)
  const [pageEnd, setPageEnd] = useState(page + PER_SIDE)

  const setPageRange = useCallback(() => {
    let start = page - PER_SIDE,
      end = page + PER_SIDE;

    console.log('range', start, end)

    if (end > numPages) {
      start -= (end - numPages);
      end = numPages;
    }

    console.log('range', start, end)

    if (start <= 0) {
      end += ((start - 1) * (-1));
      start = 1;
    }

    console.log('range', start, end)

    end = end > numPages ? numPages : end;

    console.log('setting range', start, end)

    setPageStart(start)
    setPageEnd(end)
  }, [numPages, page])

  useEffect(() => { setPageRange() }, [setPageRange])

  return (
    <div className="pagination flex justify-center">
      <span className="relative z-0 inline-flex shadow-sm rounded-md">
        <button
          type="button"
          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none disabled:bg-gray-100 disabled:text-gray-300"
          disabled={page === 1}
          onClick={() => setPage(1)}
        >
          <span className="sr-only">First</span>
          <ChevronDoubleLeftIcon className="h-4 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="-ml-px relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none disabled:bg-gray-100 disabled:text-gray-300"
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-4 w-5" aria-hidden="true" />
        </button>
        {range(pageStart, (pageEnd + 1)).map(i => (
          <button
            key={i}
            type="button"
            className={classNames(
              "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium focus:z-10 focus:outline-none disabled:bg-gray-100 disabled:text-gray-300",
              page === i ? 'font-medium bg-brand-green text-white' : 'text-gray-500 hover:bg-gray-50'
            )}
            onClick={() => setPage(i)}
          >
            {i}
          </button>
        ))}
        <button
          type="button"
          className="-ml-px relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none disabled:bg-gray-100 disabled:text-gray-300"
          disabled={page >= numPages}
          onClick={() => setPage(page + 1)}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-4 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none disabled:bg-gray-100 disabled:text-gray-300"
          disabled={page >= numPages}
          onClick={() => setPage(numPages)}
        >
          <span className="sr-only">Last</span>
          <ChevronDoubleRightIcon className="h-4 w-5" aria-hidden="true" />
        </button>
      </span>
    </div>
  )
}