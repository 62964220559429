import axios, { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import api from "../api";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { mealSelector, pushMeal, removeMeal, ROUTE } from "../store/mealSlice";
import { NotificationContext } from "../context/NotificationContext";
import { Errors } from "../types/errors";
import { blankMeal, Meal as MealModel } from "../types/meal";
import BackButton from "./BackButton";
import DeleteModal from "./DeleteModal";
import Input from "./form/Input";
import Select from "./form/Select";
import Textarea from "./form/Textarea";
import { TitleContext } from "../context/TitleContext";
import ColourPicker from "./form/ColourPicker";
import { ColorResult } from "react-color";

const types = [
  { id: 'hot', name: 'Hot' },
  { id: 'cold', name: 'Cold' },
  { id: 'allergy', name: 'Allergy' },
]

export default function Meal(props: RouteComponentProps<{ id?: string }>) {
  const id = props.match.params.id
  const titleContext = useContext(TitleContext)
  const notificationContext = useContext(NotificationContext)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const actualMeal = useAppSelector(state => mealSelector(state, id))
  const [meal, setMeal] = useState<MealModel>({ ...blankMeal })
  const [price, setPrice] = useState('0.00')
  const [errors, setErrors] = useState<Errors>({})
  const [saving, setSaving] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  useEffect(() => {
    titleContext.setTitle(actualMeal ? actualMeal.name : 'Create Meal')
  }, [titleContext, actualMeal])

  useEffect(() => {
    if (actualMeal) {
      setMeal(actualMeal)
      if (actualMeal.price) setPrice((parseFloat(actualMeal.price) / 100).toFixed(2))
    }
  }, [actualMeal])

  useEffect(() => {
    setMeal(meal => ({ ...meal, price: (Math.ceil(parseFloat(price) * 100)).toString() }))
  }, [price])

  const onFieldChange = (field: string, value: any) => {
    setMeal({ ...meal, [field]: value })
  }

  const onDelete = async () => {
    if (!actualMeal) return

    try {
      await api.delete(`/meals/${actualMeal.id}`)
      dispatch(removeMeal(actualMeal))
      history.goBack()
      notificationContext.showNotification('success', 'Meal successfully deleted!')
    } catch (error) {
      notificationContext.showNotification('error', 'Failed to deleted meal.')
    }
  }

  const onSave = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSaving(true)
    setErrors({})

    let response: AxiosResponse<any>
    try {
      if (actualMeal?.id) {
        response = await api.put(`${ROUTE}/${actualMeal.id}`, meal)
      } else {
        response = await api.post(ROUTE, meal)
      }

      const data = response.data.data
      dispatch(pushMeal(data))
      notificationContext.showNotification('success', 'Meal successfully saved!')
      history.replace(`/meals/${data.id}`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          setErrors(error.response?.data.errors)
        }
      }
    }

    setSaving(false)
  }

  const getError = (field: string) => {
    if (errors[field]) return errors[field][0]
  }

  return (
    <div className="max-w-4xl flex flex-col mx-auto">
      <BackButton className="mr-auto" />
      <form className="text-left space-y-8 divide-y divide-gray-200" onSubmit={onSave}>
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            <div>
              <h2 className="text-xl font-bold font-cursive text-brand-green-dark">Meal Information</h2>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <Input
                  label="Name"
                  error={getError('name')}
                  type="text"
                  name="name"
                  id="name"
                  value={meal.name}
                  onChange={event => onFieldChange('name', event.target.value)}
                />
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-1">
                <Input
                  label="Price"
                  hint="Value in £ (GBP)"
                  error={getError('price')}
                  type="text"
                  name="price"
                  id="price"
                  placeholder="0.00"
                  value={price}
                  onChange={event => setPrice(event.target.value)}
                  onBlur={event => setPrice(parseFloat(event.target.value).toFixed(2))}
                />
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <Select
                  label="Type"
                  options={types}
                  selectedId={meal.dinner_type}
                  onChange={selected => onFieldChange('dinner_type', selected?.id)}
                  error={getError('dinner_type')}
                />
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <Input
                  label="Item Code"
                  error={getError('item_code')}
                  type="text"
                  name="item_code"
                  id="item_code"
                  value={meal.item_code}
                  onChange={event => onFieldChange('item_code', event.target.value)}
                />
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <Textarea
                  rows={4}
                  label="Additional Information"
                  error={getError('additional_info')}
                  name="additional_info"
                  id="additional_info"
                  value={meal.additional_info}
                  onChange={event => onFieldChange('additional_info', event.target.value)}
                />
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <Textarea
                  rows={4}
                  label="Dietary Information"
                  error={getError('dietary_info')}
                  name="dietary_info"
                  id="dietary_info"
                  value={meal.dietary_info}
                  onChange={event => onFieldChange('dietary_info', event.target.value)}
                />
              </div>

              {/* <div className="sm:col-span-3">
                <Textarea
                  rows={4}
                  label="Allergy Information"
                  error={getError('allergy_info')}
                  name="allergy_info"
                  id="allergy_info"
                  value={meal.allergy_info}
                  onChange={event => onFieldChange('allergy_info', event.target.value)}
                />
              </div> */}
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <ColourPicker
                  label="Colour"
                  error={getError('colour')}
                  name="colour"
                  color={meal.colour}
                  onChangeComplete={(color: ColorResult, event) => {
                    console.log('color', color)
                    onFieldChange('colour', color.hex)
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="brand-button-tertiary"
              onClick={() => history.goBack()}
              disabled={saving}
            >
              Cancel
            </button>
            {
              actualMeal &&
              <button
                type="button"
                className="ml-3 brand-button-secondary bg-red-100 hover:bg-red-50 text-red-600"
                onClick={() => setDeleteOpen(true)}
                disabled={saving}
              >
                Delete
              </button>
            }
            <button
              type="submit"
              className="ml-3 brand-button"
              disabled={saving}
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <DeleteModal open={deleteOpen} setOpen={setDeleteOpen} onDelete={onDelete} />
    </div>
  )
}
