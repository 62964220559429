import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, ThunkAPI } from ".";
import api from "../api";
import { Term } from "../types/term";

export const ROUTE = '/school-terms';

type State = {
  terms: Term[]
}

const initialState: State = {
  terms: []
}

export const fetchTerms = createAsyncThunk<Term[], string | undefined, ThunkAPI>(
  'terms/fetch',
  async (school, thunkAPI) => {
    const response = await api.get(ROUTE, { params: { school } })
    return response.data.data
  }
)

export const termSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    pushTerm: (state, action: PayloadAction<Term>) => {
      const termIdx = state.terms.findIndex(term => term.id === action.payload.id)
      if (termIdx !== -1) {
        state.terms[termIdx] = action.payload
      } else {
        state.terms.push(action.payload)
      }
    },
    removeTerm: (state, action: PayloadAction<Term>) => {
      const termIdx = state.terms.findIndex(term => term.id === action.payload.id)
      if (termIdx >= 0) {
        state.terms.splice(termIdx, 1)
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTerms.fulfilled, (state, action) => {
      // state.terms = action.payload
      action.payload.forEach(term => {
        const termIdx = state.terms.findIndex(t => t.id === term.id)
        if (termIdx !== -1) {
          state.terms[termIdx] = term
        } else {
          state.terms.push(term)
        }
      })
    })
  },
})

export const termSelector = createSelector<RootState, string | undefined, Term[], string | undefined, Term | undefined>(
  state => state.term.terms,
  (_, id) => id,
  (terms, id) => id ? terms.find(term => term.id === id) : undefined
)

export const schoolTermSelector = createSelector<RootState, string | undefined, Term[], string | undefined, Term[]>(
  state => state.term.terms,
  (_, id) => id,
  (terms, id) => id ? terms.filter(term => term.schoolId === id) : []
)

export const { pushTerm, removeTerm } = termSlice.actions

export default termSlice.reducer