import { configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice'
import bookingSlice from './bookingSlice'
import importSlice from './importSlice'
import mealSlice from './mealSlice'
import menuSlice from './menuSlice'
import schoolSlice from './schoolSlice'
import staffSlice from './staffSlice'
import studentSlice from './studentSlice'
import termSlice from './termSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    school: schoolSlice,
    student: studentSlice,
    meal: mealSlice,
    menu: menuSlice,
    term: termSlice,
    booking: bookingSlice,
    import: importSlice,
    staff: staffSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type ThunkAPI = { dispatch: AppDispatch, state: RootState }