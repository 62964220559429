import axios, { AxiosError, AxiosInstance } from "axios";
import { auth } from "../auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || 'https://api.lovefood.mgpp.uk/api',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}) as AxiosInstance

api.interceptors.response.use(
  response => response,
  async (error: AxiosError<any>) => {
    const originalRequest = error.config
    // @ts-ignore
    const isRetry = error.config.retry
    if (error.response?.status === 401 && !isRetry) {
      // attempt a token refresh, and retry the original request
      const freshToken = await auth.currentUser?.getIdToken();
      if (freshToken) {
        // @ts-ignore
        originalRequest.retry = true;
        originalRequest.headers.Authorization = 'Bearer ' + freshToken
        api.defaults.headers.common['Authorization'] = 'Bearer ' + freshToken;

        return api(originalRequest)
      }
    } else if (error.response?.status === 401) {
      auth.signOut()
    }

    throw error
  }
)

export const setBearerToken = (token?: string) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete api.defaults.headers.common['Authorization']
  }
}

export default api
