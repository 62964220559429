import { ArrowLeftIcon } from "@heroicons/react/solid"
import { useHistory } from "react-router-dom"
import { classNames } from "../utils"

export default function BackButton({ className, onClick }: { className?: string | undefined, onClick?: () => void }) {
  const history = useHistory()

  return (
    <button
      onClick={() => {
        if (onClick) {
          onClick()
        } else {
          history.goBack()
        }
      }}
      className={classNames('brand-button-secondary flex items-center', className)}
    >
      <ArrowLeftIcon className="w-4 h-4 mr-1" />
      Back
    </button>
  )
}