import { useEffect } from "react"
import { useContext, useState } from "react"
import { TitleContext } from "../context/TitleContext"
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { fetchParents } from "../store/studentSlice";
import { Parent } from "../types/parent";
import { classNames } from "../utils";
import BackButton from "./BackButton"
import BalanceReportRow from "./BalanceReportRow";
import Select, { SelectOption } from "./form/Select";
import Pagination from "./Pagination";

const PER_PAGE = 10

export default function BalanceReport() {
  const dispatch = useAppDispatch()
  const titleContext = useContext(TitleContext)
  const [loading, setLoading] = useState(true)
  const [debt, setDebt] = useState(false)
  const parents = useAppSelector(state => state.student.parents)
  const [rows, setRows] = useState<Parent[]>([])
  const [page, setPage] = useState(1)
  const [numPages, setNumPages] = useState(1)
  const schools = useAppSelector(state => state.school.schools)
  const [options, setOptions] = useState<SelectOption[]>([])
  const [school, setSchool] = useState<string>()

  useEffect(() => {
    // setSchool(schools.length > 0 ? schools[0].id : undefined)
    setOptions([
      { id: undefined, name: 'All Schools' },
      ...schools.map(school => ({ id: school.id!, name: school.name })),
    ])
  }, [schools])

  useEffect(() => {
    titleContext.setTitle('Balance Report')
  }, [titleContext])

  useEffect(() => {
    setLoading(true)
    dispatch(school ? fetchParents(school) : fetchParents()).finally(() => setLoading(false))
  }, [dispatch, school])

  useEffect(() => {
    setRows(parents.filter(p => debt ? (p.balance && p.balance < 0) : true))
    setPage(1)
  }, [parents, debt])

  useEffect(() => {
    setNumPages(Math.ceil(rows.length / PER_PAGE))
  }, [rows])

  return (
    <div className="flex flex-col">
      <div className="mb-4 flex justify-end">
        <BackButton className="print:hidden mr-auto" />
        <div className="mr-4 w-60">
          <Select
            label=""
            options={options}
            selectedId={school}
            onChange={selected => setSchool(selected?.id)}
          />
        </div>
        <button
          className={classNames(
            "print:hidden brand-button-secondary flex items-center mr-4",
            debt ? "text-brand-green-dark ring-2 ring-brand-green-dark" : undefined
          )}
          onClick={() => setDebt(!debt)}
        >
          Debt Only
        </button>
        <button className="print:hidden brand-button flex items-center" onClick={() => { window.print() }}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
          </svg>
          Print
        </button>
      </div>
      <div className="-my-2 overflow-x-auto overflow-y-visible sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg print:rounded-none print:shadow-none print:border-none">
            <table className="min-w-full divide-y divide-gray-200 text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="bg-gray-50 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky-left font-medium"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-50 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky-left"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-50 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky-left"
                  >
                    Mobile
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-50 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky-left"
                  >
                    Balance
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-50 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky-left"
                  >
                    &nbsp;
                  </th>

                </tr>
              </thead>
              <tbody>
                {
                  loading ? (
                    <tr className="bg-white">
                      <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        Loading...
                      </td>
                    </tr>
                  ) : (
                    rows && rows.length > 0 ? (
                      rows
                        .slice((page - 1) * PER_PAGE, Math.min(rows.length, page * PER_PAGE))
                        .map((row, rowIdx) => (
                          <BalanceReportRow key={rowIdx} parent={row} rowIdx={rowIdx} />
                        ))
                    ) : (
                      <tr className="bg-white">
                        <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          No balance information available at this time.
                        </td>
                      </tr>
                    )
                  )
                }
              </tbody>
            </table>
          </div>
          {numPages > 1 && (
            <div className="mt-4">
              <Pagination page={page} numPages={numPages} setPage={setPage} />
            </div>
          )}
        </div>
      </div>
    </div >
  )
}
