import { Fragment, useContext, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import {
  Switch,
  Route,
  NavLink,
  useLocation,
  useHistory,
} from "react-router-dom";
import Schools from '../Schools';
import { auth } from '../../auth';
import Students from '../Students';
import Student from '../Student';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { resetAuthState } from '../../store/authSlice';
import slugify from 'slugify';
import CateringReport from '../CateringReport';
import School from '../School';
import ServiceReport from '../ServiceReport';
import logo from '../../assets/lf-logo.png'
import { classNames } from '../../utils';
import Meals from '../Meals';
import Meal from '../Meal';
import Menus from '../Menus';
import MenuScreen from '../Menu';
import Terms from '../Terms';
import Term from '../Term';
import { TitleContext } from '../../context/TitleContext';
import { format } from 'date-fns';
import Imports from '../Imports';
import Staff from '../Staff';
import EditStaff from '../EditStaff';
import BillingReport from '../BillingReport';
import WeeklyBillingReport from '../WeeklyBillingReport';
import ChangePasswordModal from '../ChangePasswordModal';
import BalanceReport from '../BalanceReport';

// const navigation = ['Schools', 'Meals', 'Menus', 'Reports']
// const topLevelNavigation = ['/staff', '/meals', '/menus', '/imports', '/catering-report', '/service-report', '/balance-report']
const profile = ['Change Password', 'Sign out']

// { children }: { children: ReactNode }

export default function BrandNav() {
  const titleContext = useContext(TitleContext)
  const dispatch = useAppDispatch()
  const type = useAppSelector(state => state.auth.type)
  const history = useHistory()
  const location = useLocation<{ title: string }>();
  const [navigation, setNavigation] = useState<string[]>([])
  const [topLevelNavigation, setTopLevelNavigation] = useState<string[]>([])
  const [changePassword, setChangePassword] = useState(false)

  useEffect(() => {
    const topLevel = ['/staff', '/meals', '/menus', '/imports', '/catering-report', '/service-report', '/balance-report']
    if (type === 'admin') {
      setNavigation(['Schools', 'Staff', 'Meals', 'Menus', 'Imports', 'Catering Report', 'Service Report', 'Balance Report'])
      setTopLevelNavigation(topLevel)
    } else {
      setNavigation(['Students', 'Catering Report', 'Service Report', 'Billing Report', 'Balance Report'])
      setTopLevelNavigation([...topLevel, '/billing-report'])
    }
  }, [history, type])

  const onProfileAction = async (action: string) => {
    console.log('action', action)
    if (action === 'change-password') {
      setChangePassword(true)
    } else {
      signOut()
    }
  }

  const signOut = async () => {
    await auth.signOut()
    dispatch(resetAuthState())
    history.replace('/')
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <div>
        <Disclosure as="nav" className="print:hidden bg-brand-red border-b border-white border-opacity-25 lg:border-none">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <div className="relative h-24 flex items-center justify-between lg:border-b lg:border-white lg:border-opacity-25">
                  <div className="px-2 flex items-center lg:px-0">
                    <div className="flex-shrink-0 w-60">
                      <img
                        className="hidden lg:block w-60 absolute top-0"
                        src={logo}
                        alt="Logo"
                      />
                    </div>
                    <div className="hidden lg:block lg:ml-10">
                      <div className="flex items-center space-x-4">
                        {navigation.map((item, itemIdx) =>
                          <NavLink
                            key={item}
                            to={{
                              pathname: itemIdx !== 0
                                ? `/${slugify(item.toLowerCase())}`
                                : '/',
                              state: { title: item },
                            }}
                            exact={itemIdx === 0}
                            isActive={(match, location) => {
                              if (match) return true

                              return itemIdx !== 0
                                ? false
                                : !topLevelNavigation.some(nav => nav === location.pathname || location.pathname.startsWith(nav))
                            }}
                            className="text-white hover:underline rounded-xl py-2 px-3 text-sm font-medium"
                            activeClassName="bg-brand-green text-white"
                          >
                            {item}
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-brand-red p-2 rounded-md inline-flex items-center justify-center text-white hover:bg-brand-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-brand-green focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="hidden lg:block lg:ml-4">
                    <div className="flex items-center">
                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative flex-shrink-0">
                        {({ open }) => (
                          <>
                            <div>
                              <Menu.Button className="bg-brand-red rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-brand-green focus:ring-white">
                                <span className="sr-only">Open user menu</span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                                </svg>
                                {/* <img
                                  className="rounded-full h-8 w-8"
                                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                  alt=""
                                /> */}
                              </Menu.Button>
                            </div>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                              >
                                {profile.map((item) => (
                                  <Menu.Item key={item}>
                                    {({ active }) => (
                                      <button
                                        onClick={() => onProfileAction(slugify(item.toLowerCase()))}
                                        className={classNames(
                                          active ? 'bg-gray-100' : '',
                                          'block w-full py-2 px-4 text-sm text-gray-700'
                                        )}
                                      >
                                        {item}
                                      </button>
                                    )}
                                  </Menu.Item>
                                ))}
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 space-x-2">
                  {navigation.map((item, itemIdx) =>
                    <NavLink
                      key={item}
                      to={{
                        pathname: itemIdx !== 0
                          ? `/${slugify(item.toLowerCase())}`
                          : '/',
                        state: { title: item },
                      }}
                      exact={itemIdx === 0}
                      className="text-white hover:underline rounded-xl py-2 px-3 text-sm font-medium"
                      activeClassName="bg-brand-green text-white"
                    >
                      {item}
                    </NavLink>
                  )}
                </div>
                <div className="pt-4 pb-3 border-t border-white">
                  <div className="mt-3 px-2 space-y-1">
                    {profile.map((item) => (
                      <button
                        key={item}
                        onClick={() => onProfileAction(slugify(item.toLowerCase()))}
                        className="text-white hover:underline rounded-xl py-2 px-3 text-sm font-medium hover:bg-brand-green hover:bg-opacity-60"
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <header className="py-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold font-cursive text-brand-green-dark print:text-black inline-flex items-center">
              {location.state?.title || titleContext.title}
            </h1>
          </div>
        </header>
      </div>

      <main className="flex-grow">
        <div className="max-w-7xl print:max-w-none mx-auto pb-12 px-4 sm:px-6 lg:px-8 print:px-0">
          <Switch>
            {type === 'admin' && <Route path="/meals/:id" component={Meal} />}
            {type === 'admin' && <Route path="/meals" component={Meals} />}
            {type === 'admin' && <Route path="/menus/:id" component={MenuScreen} />}
            {type === 'admin' && <Route path="/menus" component={Menus} />}
            <Route path="/catering-report" component={CateringReport} />
            <Route path="/service-report" component={ServiceReport} />
            <Route exact path="/billing-report/:id" component={WeeklyBillingReport} />
            <Route exact path="/billing-report" component={BillingReport} />
            <Route exact path="/balance-report" component={BalanceReport} />
            {type === 'admin' && <Route exact path="/terms/:id" component={Term} />}
            {type === 'admin' && <Route exact path="/terms" component={Terms} />}
            {type === 'admin' && <Route exact path="/imports" component={Imports} />}
            {type === 'admin' && <Route exact path="/staff/:id" component={EditStaff} />}
            {type === 'admin' && <Route exact path="/staff" component={Staff} />}
            <Route path={'/students/:id'} component={Student} />
            <Route path={'/students'} component={Students} />
            <Route exact path="/:id" component={type === 'admin' ? School : Student} />
            <Route exact path="/" component={type === 'admin' ? Schools : Students} />
          </Switch>
        </div>
      </main>
      <footer className="text-left min-h-32 text-white print:hidden">
        <div className="bg-brand-red">
          <div className="max-w-7xl mx-auto p-2 sm:p-4 lg:p-8 text-xl">
            <div>
              <h4 className="font-cursive uppercase">
                Call Us:
              </h4>
              <h4 className="font-cursive">
                07789103884
              </h4>
            </div>
            <div className="mt-6">
              <h4 className="font-cursive uppercase">
                Email Us:
              </h4>
              <h4 className="font-cursive">
                lovefoodjm@outlook.com
              </h4>
            </div>
          </div>
        </div>
        <div className="bg-brand-red bg-opacity-90">
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 py-4 text-sm">
            <div className="flex justify-between">
              <h6>
                &copy; {format(new Date(), 'Y')} LOVE FOOD JM LTD.
              </h6>
            </div>
          </div>
        </div>
      </footer>
      <ChangePasswordModal open={changePassword} setOpen={() => setChangePassword(false)} />
    </div>
  )
}
