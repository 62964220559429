import { Fragment, useContext, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Student } from '../types/student'
import Select from './form/Select'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { format, getUnixTime, startOfWeek } from 'date-fns'
import Input from './form/Input'
import api from '../api'
import { NotificationContext } from '../context/NotificationContext'
import axios from 'axios'
import { Errors } from '../types/errors'
import { useEffect } from 'react'
import { Term } from '../types/term'
import { Menu } from '../types/menu'
import { fetchMenus } from '../store/menuSlice'

type BookingModalProps = {
  booking: { date: Date, student: Student }
  open: boolean
  setOpen: (open: boolean) => void
}

const dateFormat = 'yyyy-MM-dd'

export default function BookingModal({ booking, open, setOpen }: BookingModalProps) {
  const notificationContext = useContext(NotificationContext)
  const dispatch = useAppDispatch()
  const menus = useAppSelector(state => state.menu.menus)
  const cancelButtonRef = useRef(null)
  const [saving, setSaving] = useState(false)
  const [menu, setMenu] = useState<Menu>()
  const [meal, setMeal] = useState<string>()
  const [errors, setErrors] = useState<Errors>({})
  const [message, setMessage] = useState<string>()

  useEffect(() => {
    if (menus.length === 0) dispatch(fetchMenus())
  }, [dispatch, menus])

  useEffect(() => {
    const date = getUnixTime(booking.date)

    if (!date) return

    const loadMeals = async () => {
      const response = await api.get('/school-terms', { params: { school: booking.student.schoolId } })
      const term: Term | undefined = response.data.data.find((t: Term) => {
        return t.start
          && t.end
          && date >= t.start
          && date < t.end
      })

      if (!term?.menus) return

      const menu = term.menus[format(startOfWeek(booking.date, { weekStartsOn: 1 }), dateFormat)]
      setMenu(menus.find(m => m.id === menu))
    }

    loadMeals()
  }, [booking, menus])

  const onSave = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSaving(true)

    try {
      await api.post('/bookings', { student: booking.student.id, bookings: [{ bookingDate: format(booking.date, 'yyyy-MM-dd'), menuId: menu?.id, mealId: meal }] })
      notificationContext.showNotification('success', 'Booking successfully created!')
      setSaving(false)
      setOpen(true)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          setErrors(error.response?.data.errors)
          setMessage(error.response?.data.message)
        }
      }

      notificationContext.showNotification('error', 'Failed to create booking.')
      setSaving(false)
    }
  }

  const getError = (field: string) => {
    if (errors[field]) return errors[field][0]
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6 sm:h-screen-70">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-dark"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="h-full sm:flex sm:items-start">
                <div className="h-full flex flex-col flex-grow mt-3 sm:mt-0 text-center sm:text-left">
                  <Dialog.Title as="h2" className="text-xl font-bold font-cursive text-brand-green-dark">
                    Book Meal for {booking.student.forename} {booking.student.surname}
                    {/* on {format(booking.date, 'dd/MM/yyyy')} */}
                  </Dialog.Title>
                  {message && <p className="mt-2 text-sm text-red-600">{message}</p>}
                  <form className="text-left space-y-8 divide-y divide-gray-200" onSubmit={onSave}>
                    <div className="space-y-8 divide-y divide-gray-200">
                      <div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-4">
                            <Input
                              label="Date"
                              type="text"
                              value={format(booking.date, 'dd/MM/yyyy')}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-4">
                            <Select
                              label="Meal"
                              options={
                                menu?.meals
                                  ?.filter(meal => {
                                    return meal.year_groups?.includes(booking.student.year)
                                      && meal.days_available?.includes(format(booking.date, 'E').toLowerCase())
                                  })
                                  ?.map(meal => ({ id: meal.id!, name: meal.name })) || [{ id: '', name: 'No meals available for this date', disabled: true }]
                              }
                              selectedId={meal}
                              onChange={selected => setMeal(selected!.id)}
                              error={getError(`bookings.0.meal`)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="mt-auto pt-5 border-t">
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="brand-button-tertiary"
                        onClick={() => setOpen(false)}
                        disabled={saving}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="ml-3 brand-button"
                        disabled={saving}
                        onClick={onSave}
                      >
                        Book!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
