import { Fragment, ReactElement, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'
import { classNames } from '../../utils'

export type NotificationType = 'success' | 'error' | 'info'

export type NotificationProps = {
  show: boolean
  type: NotificationType
  title: string
  description?: string
}

const successIcon = <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
const errorIcon = <ExclamationCircleIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
const infoIcon = <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />

export default function Notification({ show, type, title, description }: NotificationProps) {
  const [bgColor, setBgColor] = useState('bg-gray-100')
  const [icon, setIcon] = useState<ReactElement>(infoIcon)

  useEffect(() => {
    if (type === 'success') {
      setBgColor('bg-green-50')
      setIcon(successIcon)
    } else if (type === 'error') {
      setBgColor('bg-red-50')
      setIcon(errorIcon)
    } else {
      setBgColor('bg-gray-100')
      setIcon(infoIcon)
    }
  }, [type])

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-50 text-left fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={classNames(
              "max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden",
              bgColor
            )}>
              <div className="p-4">
                <div className="flex items-center">
                  <div className="w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                    {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
                  </div>
                  <div className="flex-shrink-0">
                    {icon}
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}
