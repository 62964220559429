import axios, { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import api from "../api";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { termSelector, pushTerm, ROUTE, removeTerm } from "../store/termSlice";
import { Errors } from "../types/errors";
import { Term as TermModel } from "../types/term";
import BackButton from "./BackButton";
import DatePicker from "./form/DatePicker";
import DeleteModal from "./DeleteModal";
import { addWeeks, format, fromUnixTime, getUnixTime, isAfter, parse, startOfWeek } from "date-fns";
import Select from "./form/Select";
import { PlusIcon } from "@heroicons/react/outline";
import { NotificationContext } from "../context/NotificationContext";
import { TitleContext } from "../context/TitleContext";

const dateStringFormat = 'yyyy-MM-dd'
const humanFormat = 'dd/MM/yyyy'
const toDateString = (date: Date) => format(date, dateStringFormat)

export default function Term(props: RouteComponentProps<{ id?: string }>) {
  const id = props.match.params.id
  const titleContext = useContext(TitleContext)
  const notificationContext = useContext(NotificationContext)
  const history = useHistory()
  const search = useLocation().search
  const dispatch = useAppDispatch()
  const actualTerm = useAppSelector(state => termSelector(state, id))
  const availableMenus = useAppSelector(state => state.menu.menus)
  const [school, setSchool] = useState<string>('')
  const [term, setTerm] = useState<TermModel>({})
  const [weeks, setWeeks] = useState<{ [date: string]: string | undefined }>({})
  const [excludedDates, setExcludedDates] = useState<string[]>([''])
  const [errors, setErrors] = useState<Errors>({})
  const [saving, setSaving] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  useEffect(() => {
    titleContext.setTitle(actualTerm ? 'Edit Term' : 'Create Term')
  }, [titleContext, actualTerm])

  useEffect(() => {
    const query = new URLSearchParams(search)
    const school = query.get('school')
    if (school) {
      setSchool(school)
    } else {
      history.goBack()
    }
  }, [search, history])

  useEffect(() => {
    if (actualTerm) {
      setWeeks(actualTerm.menus || {})
      setTerm(actualTerm)
      if (actualTerm.excludedDates && actualTerm.excludedDates.length > 0) {
        setExcludedDates(actualTerm.excludedDates)
      } else {
        setExcludedDates([''])
      }
    }
  }, [actualTerm])

  useEffect(() => {
    if (!term.start || !term.end) return

    const termStart = fromUnixTime(term.start)
    const termEnd = fromUnixTime(term.end)
    const start = startOfWeek(termStart, { weekStartsOn: 1 })

    setWeeks(weeks => {
      const newWeeks = { [toDateString(start)]: weeks[toDateString(start)] }
      let currentWeek = start

      while (true) { // isBefore(currentWeek, termEnd)
        currentWeek = addWeeks(currentWeek, 1)
        if (isAfter(currentWeek, termEnd)) break
        const dateString = toDateString(currentWeek)
        newWeeks[dateString] = weeks[dateString]
      }

      return newWeeks
    })
  }, [term])

  const onFieldChange = (field: string, value: any) => {
    setTerm(term => ({ ...term, [field]: value }))
  }

  const onDelete = async () => {
    if (!actualTerm) return

    try {
      await api.delete(`/school-terms/${actualTerm.id}`)
      dispatch(removeTerm(actualTerm))
      history.goBack()
      notificationContext.showNotification('success', 'Term successfully deleted!')
    } catch (error) {
      notificationContext.showNotification('error', 'Failed to deleted term.')
    }
  }

  const onSave = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSaving(true)
    setErrors({})

    const errors: Errors = {}
    Object.keys(weeks)
      .forEach(key => {
        if (weeks[key] === undefined) errors[key] = [`A menu is required`]
      })

    if (Object.keys(errors).length > 0) {
      setSaving(false)
      setErrors(errors)
      return
    }

    let response: AxiosResponse<any>
    const payload = { ...term, schoolId: school, menus: weeks, excludedDates: excludedDates.filter(Boolean) }

    try {
      if (actualTerm?.id) {
        response = await api.put(`${ROUTE}/${actualTerm.id}`, payload)
      } else {
        response = await api.post(ROUTE, payload)
      }

      const data = response.data.data
      dispatch(pushTerm(data))
      notificationContext.showNotification('success', 'Term successfully saved!')
      history.replace(`/terms/${data.id}?school=${school}`, { title: 'Edit Term' })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          setErrors(error.response?.data.errors)
        }
      }
    }

    setSaving(false)
  }

  const getError = (field: string) => {
    if (errors[field]) return errors[field][0]
  }

  return (
    <div className="max-w-4xl flex flex-col mx-auto">
      <BackButton className="mr-auto" />
      <form className="text-left space-y-8 divide-y divide-gray-200" onSubmit={onSave}>
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            <div>
              <h2 className="text-xl font-bold font-cursive text-brand-green-dark">Term Dates</h2>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <DatePicker
                  label="Start"
                  date={term.start}
                  onChange={date => {
                    onFieldChange('start', date)
                    if (term.end && date && date > term.end) {
                      onFieldChange('end', date)
                    }
                  }}
                  error={getError('start')}
                />
              </div>
              <div className="sm:col-span-3">
                <DatePicker
                  label="End"
                  date={term.end}
                  min={term.start}
                  onChange={date => onFieldChange('end', date)}
                  error={getError('end')}
                />
              </div>
            </div>
          </div>

          <div className="pt-8">
            <div>
              <h2 className="text-xl font-bold font-cursive text-brand-green-dark">Menus</h2>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              {
                Object.keys(weeks).map((weekKey, idx) => (
                  <div key={weekKey} className="sm:col-span-2">
                    <Select
                      label={`Week ${idx + 1} (${format(parse(weekKey, dateStringFormat, new Date()), humanFormat)})`}
                      options={availableMenus.map(menu => ({ id: menu.id!, name: menu.name }))}
                      selectedId={weeks[weekKey]}
                      onChange={selected => setWeeks(weeks => ({ ...weeks, [weekKey]: selected?.id }))}
                      error={getError(weekKey)}
                    />
                  </div>
                ))
              }
            </div>
          </div>

          <div className="pt-8">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-xl font-bold font-cursive text-brand-green-dark">Excluded Dates</h2>
                {/* <h3 className="font-cursive">Users will not be able to book meals on these dates</h3> */}
              </div>
              <button type="button" className="brand-button-secondary flex items-center" onClick={() => setExcludedDates(dates => [...dates, ''])}>
                <PlusIcon className="w-4 h-4 mr-2" />
                Add
              </button>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              {
                excludedDates.map((dateString, dateIdx) => {
                  const date = parse(dateString, dateStringFormat, new Date())
                  const unix = getUnixTime(date)

                  return (
                    <div key={dateIdx} className="sm:col-span-2">
                      <DatePicker
                        label=''
                        date={unix}
                        min={term.start}
                        max={term.end}
                        pin="top"
                        disableWeekends
                        onChange={
                          date => date && setExcludedDates(dates => {
                            const newDates = [...dates]
                            newDates.splice(dateIdx, 1, format(fromUnixTime(date), dateStringFormat))
                            return newDates
                          })
                        }
                        onClear={() => setExcludedDates(dates => {
                          const newDates = [...dates]
                          newDates.splice(dateIdx, 1)
                          return newDates.length === 0 ? [''] : newDates
                        })}
                      />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="brand-button-tertiary"
              onClick={() => history.goBack()}
              disabled={saving}
            >
              Cancel
            </button>
            {
              actualTerm &&
              <button
                type="button"
                className="ml-3 brand-button-secondary bg-red-100 hover:bg-red-50 text-red-600"
                onClick={() => setDeleteOpen(true)}
                disabled={saving}
              >
                Delete
              </button>
            }
            <button
              type="submit"
              className="ml-3 brand-button"
              disabled={saving}
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <DeleteModal open={deleteOpen} setOpen={setDeleteOpen} onDelete={onDelete} />
    </div>
  )
}
