import { useEffect, useState, useContext } from "react";
import { Parent } from "../types/parent";
import { classNames, currencyFormatter } from "../utils";
import api from "../api"
import { NotificationContext } from "../context/NotificationContext";



export default function BalanceReportRow({ parent, rowIdx }: { parent: Parent, rowIdx: number }) {
  const [balance, setBalance] = useState(0)
  const notificationContext = useContext(NotificationContext)

  useEffect(() => {
    setBalance((parent.balance || 0) / 100)
  }, [parent])

  const onRemind = async (id?: string) => {
    try {
      await api.post(`balance/mail/${id}`)
      notificationContext.showNotification('success', 'Reminder successfully sent.')
    } catch (error) {
      notificationContext.showNotification('error', 'Unable to send reminder at this time.')
    }
  }
  return (
    <tr key={rowIdx} className={[rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50', 'print:border-b print:border-l print:border-r'].join(' ')}>
      <td className="px-4 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500 font-medium">
        {
          [parent.title, `${parent.forename} ${parent.surname}`]
            .filter(s => s && s.length > 0)
            .join(' ')
        }
      </td>
      <td className="px-4 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500">
        {parent.email}
      </td>
      <td className="px-4 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500">
        {parent.mobile}
      </td>
      <td className={classNames(
        "px-4 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500",
        balance < 0 ? "text-red-600" : ""
      )}>
        {currencyFormatter.format(balance)}
      </td>
      <td className="px-4 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500">
        {balance < 0 &&
          <button type="button" className="brand-link mr-2" data-tip data-for="invoice" onClick={() => onRemind(parent.id)}>
            Send Reminder
          </button>
        }
      </td>
    </tr>
  )
}