import { ExclamationIcon } from "@heroicons/react/outline"
import axios, { AxiosResponse } from "axios"
import { useContext, useEffect, useState } from "react"
import { RouteComponentProps, useHistory } from "react-router-dom"
import api from "../api"
import { NotificationContext } from "../context/NotificationContext"
import { TitleContext } from "../context/TitleContext"
import { useAppDispatch, useAppSelector } from "../hooks/useRedux"
import useSchoolQuery from "../hooks/useSchoolQuery"
import { fetchStudents, pushStudent, ROUTE, studentSelector } from "../store/studentSlice"
import { Errors } from "../types/errors"
import { blankStudent, Student as StudentModel } from "../types/student"
import { classNames } from "../utils"
import BackButton from "./BackButton"
import ParentInformation from "./ParentInformation"
import StudentInformation from "./StudentInformation"

type TabKey = 'student' | 'parent' | 'bookings'

const tabs: { key: TabKey, name: string }[] = [
  { key: 'student', name: 'Student' },
  { key: 'parent', name: 'Parent' },
  // { key: 'bookings', name: 'Bookings' },
]

const parentFields = ['parent_title', 'parent_forename', 'parent_surname', 'parent_mobile', 'parent_email']

export default function Student(props: RouteComponentProps<{ id?: string }>) {
  const id = props.match.params.id
  const schoolQuery = useSchoolQuery()
  const titleContext = useContext(TitleContext)
  const notificationContext = useContext(NotificationContext)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const schools = useAppSelector(state => state.school.schools)
  const students = useAppSelector(state => state.student.students)
  const actualStudent = useAppSelector(state => studentSelector(state, id))
  const [student, setStudent] = useState<StudentModel>({ ...blankStudent })
  const [errors, setErrors] = useState<Errors>({})
  const [saving, setSaving] = useState(false)
  const [activeTab, setActiveTab] = useState<TabKey>('student')
  const [hasStudentError, setHasStudentError] = useState(true)
  const [hasParentError, setHasParentError] = useState(false)

  useEffect(() => {
    titleContext.setTitle(actualStudent ? `${actualStudent.forename} ${actualStudent.surname}` : 'Create Student')
  }, [titleContext, actualStudent])

  useEffect(() => {
    if (schoolQuery) dispatch(fetchStudents(schoolQuery))
  }, [schools, students, dispatch, schoolQuery])

  useEffect(() => {
    if (actualStudent) return

    setStudent(student => ({
      ...student,
      schoolId: schoolQuery || student.schoolId
    }))
  }, [actualStudent, schoolQuery])

  useEffect(() => {
    if (actualStudent) setStudent(actualStudent)
  }, [actualStudent, schoolQuery])

  useEffect(() => {
    const keys = Object.keys(errors)
    setHasParentError(
      keys.some(value => parentFields.includes(value))
    )
    setHasStudentError(
      keys.some(value => !parentFields.includes(value))
    )
  }, [errors])

  const onFieldChange = (field: string, value: any) => {
    setStudent({ ...student, [field]: value })
  }

  const onSave = async () => {
    setSaving(true)
    setErrors({})

    let response: AxiosResponse<any>
    try {
      if (actualStudent?.id) {
        response = await api.put(`${ROUTE}/${actualStudent.id}`, student)
      } else {
        response = await api.post(ROUTE, student)
      }

      const data = response.data.data
      dispatch(pushStudent(data))
      notificationContext.showNotification('success', 'Student successfully saved!')
      history.replace(`/students/${data.id}${props.location.search}`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          setErrors(error.response?.data.errors)
        }
      }

      notificationContext.showNotification('error', 'Failed to save student.')
    }

    setSaving(false)
  }

  return (
    <div className="max-w-4xl flex flex-col mx-auto">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-brand-green focus:border-brand-green border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => tab.key === activeTab)!.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:flex justify-between">
        <nav className="flex justify-end space-x-6" aria-label="Breadcrumbs">
          <BackButton />
        </nav>
        <nav className="flex justify-end space-x-6" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
              className={classNames(
                tab.key === activeTab ? 'brand-button' : 'brand-link',
                tab.key === 'student' && hasStudentError ? (activeTab === 'student' ? 'bg-red-100 text-red-600' : 'text-red-600') : '',
                tab.key === 'parent' && hasParentError ? (activeTab === 'parent' ? 'bg-red-100 text-red-600' : 'text-red-600') : '',
                'flex items-center'
              )}
              aria-current={tab.key === activeTab ? 'page' : undefined}
            >
              {tab.name}
              {
                tab.key === 'student' && hasStudentError &&
                <ExclamationIcon className="ml-2 w-5 h-5 text-red-600" />
              }
              {
                tab.key === 'parent' && hasParentError &&
                <ExclamationIcon className="ml-2 w-5 h-5 text-red-600" />
              }
            </button>
          ))}
        </nav>
      </div>
      {
        activeTab === 'student' &&
        <StudentInformation
          student={student}
          errors={errors}
          saving={saving}
          onFieldChange={onFieldChange}
          onSave={onSave}
        />
      }
      {
        activeTab === 'parent' &&
        <ParentInformation
          student={student}
          errors={errors}
          saving={saving}
          onFieldChange={onFieldChange}
          onSave={onSave}
        />
      }
      {/* {activeTab === 'bookings' && <StudentInformation {...props} />} */}
    </div>
  )
}
