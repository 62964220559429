import { PlusIcon, PrinterIcon, UploadIcon } from "@heroicons/react/outline"
import { format, fromUnixTime, getUnixTime, startOfWeek } from "date-fns"
import { useCallback } from "react"
import { useContext, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { TitleContext } from "../context/TitleContext"
import { useAppDispatch, useAppSelector } from "../hooks/useRedux"
import { fetchBookingsForWeek } from "../store/bookingSlice"
import { schoolSelector } from "../store/schoolSlice"
import { fetchStudents, schoolStudentSelector } from "../store/studentSlice"
import { Booking } from "../types/booking"
import { Student } from "../types/student"
import { classNames } from "../utils"
import BackButton from "./BackButton"
import BookingModal from "./BookingModal"
import DatePicker from "./form/DatePicker"
import Input from "./form/Input"
import Select from "./form/Select"
import ImportModal from "./ImportModal"
import Pagination from "./Pagination"
import StudentRow from "./StudentRow"

const PER_PAGE = 25

const years = [
  { id: undefined, name: 'All Years' },
  { id: 'teachers', name: 'Teacher' },
  { id: 'nursery', name: 'Nursery' },
  { id: 'reception', name: 'Reception' },
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
]

export default function Students() {
  const titleContext = useContext(TitleContext)
  const search = useLocation().search
  const dispatch = useAppDispatch()
  const authType = useAppSelector(state => state.auth.type)
  const schools = useAppSelector(state => state.auth.schools)
  const [school, setSchool] = useState<string>()
  const [allergyOnly, setAllergyOnly] = useState(false)
  const schoolModel = useAppSelector(state => schoolSelector(state, school))
  const students = useAppSelector(state => school ? schoolStudentSelector(state, school) : [])
  const [filteredStudents, setFilteredStudents] = useState<Student[]>([])
  const [importOpen, setImportOpen] = useState(false)
  const [date, setDate] = useState<Date>(new Date())
  const [week, setWeek] = useState<Date>(startOfWeek(date))
  const [createBooking, setCreateBooking] = useState<{ date: Date, student: Student }>()
  const [nameSearch, setNameSearch] = useState('')
  const [yearSearch, setYearSearch] = useState<string>()
  const [page, setPage] = useState(1)
  const [numPages, setNumPages] = useState(1)

  useEffect(
    () => {
      setNumPages(Math.ceil(filteredStudents.length / PER_PAGE))
      setPage(1)
    },
    [filteredStudents]
  )

  useEffect(() => {
    if (students.length > 0) {
      setFilteredStudents(
        students
          .filter(student => allergyOnly ? student.allergy?.length > 0 || student.dietary?.length > 0 : true)
          .filter(student => yearSearch ? student.year === yearSearch : true)
          .filter(student => {
            const forename = student.forename.toLowerCase()
            const surname = student.surname.toLowerCase()

            return nameSearch
              ? (
                forename.includes(nameSearch) ||
                surname.includes(nameSearch) ||
                `${forename} ${surname}`.includes(nameSearch) ||
                student.parent_email?.includes(nameSearch)
              )
              : true
          })
      )
    }
  }, [students, allergyOnly, yearSearch, nameSearch])

  const fetchBookings = useCallback((force: boolean = false) => {
    if (!school) return
    const newWeek = startOfWeek(date, { weekStartsOn: 1 })
    setWeek(newWeek)
    dispatch(fetchBookingsForWeek({ schoolId: school, date: format(newWeek, 'yyyy-MM-dd'), force }))
  }, [date, dispatch, school])

  const onBookingCancelled = () => {
    fetchBookings(true)
  }

  useEffect(() => {
    fetchBookings()
  }, [dispatch, school, date, fetchBookings])

  useEffect(() => {
    titleContext.setTitle(schoolModel ? `${schoolModel.name} - Students` : 'Students')
  }, [titleContext, schoolModel])

  useEffect(() => {
    let school
    if (schools.length === 0) {
      const query = new URLSearchParams(search)
      school = query.get('school') ?? undefined
    } else {
      school = schools[0]
    }

    setSchool(school)

    if (school) {
      dispatch(fetchStudents(school))
    } else {
      dispatch(fetchStudents())
    }
  }, [schools, search, dispatch, school])

  const bookingsByWeek = useAppSelector(state => state.booking.bookingsByWeek)
  const [bookings, setBookings] = useState<Booking[]>([])
  useEffect(() => {
    if (school && bookingsByWeek[school]) {
      setBookings(
        bookingsByWeek[school][format(week, 'yyyy-MM-dd')] || []
      )
    }
  }, [bookingsByWeek, school, week])

  return (
    <div className="flex flex-col">
      {school && <ImportModal open={importOpen} setOpen={setImportOpen} schoolId={school} />}
      <div className="print:hidden">
        <div className="mb-4 grid gap-2 md:gap-4 md:grid-cols-4 xl:flex xl:flex-wrap xl:justify-end xl:items-end">
          {authType === 'admin' && <BackButton className="print:hidden mr-auto" />}
          <div className="print:hidden w-full xl:w-64 md:col-span-1 xl:col-auto">
            <Input
              label=""
              placeholder="Search"
              type="text"
              value={nameSearch}
              onChange={event => setNameSearch(event.target.value.toLowerCase())}
            />
          </div>
          <div className="w-full xl:w-32 print:hidden">
            <Select
              options={years}
              selectedId={yearSearch}
              onChange={selected => setYearSearch(selected?.id)}
            />
          </div>
          <div className="w-full xl:w-32 md:w-auto">
            <DatePicker
              date={getUnixTime(date)}
              onChange={date => date && setDate(fromUnixTime(date))}
              dayPickerProps={{
                // showWeekNumbers: true,
                // onWeekClick: (week, days) => setDate(days[0])
              }}
            />
          </div>
          <button
            className={classNames(
              "print:hidden brand-button-secondary flex items-center",
              allergyOnly ? "text-brand-green-dark ring-2 ring-brand-green-dark" : undefined
            )}
            onClick={() => setAllergyOnly(!allergyOnly)}
          >
            Allergy Only
          </button>
          <button className="print:hidden brand-button flex items-center" onClick={() => { window.print() }}>
            <PrinterIcon className="w-4 h-4 mr-2" />
            Print
          </button>
          <button className="print:hidden brand-button flex items-center" disabled={!school} onClick={() => setImportOpen(true)}>
            <UploadIcon className="w-4 h-4 mr-2" />
            Import
          </button>
          <Link
            to={{
              pathname: `/students/create`,
              search: `?school=${school}`,
              state: { title: 'Create Student' },
            }}
            className="print:hidden brand-button flex items-center"
          >
            <PlusIcon className="w-4 h-4 mr-2" />
            Create
          </Link>
        </div>
      </div>
      <div className="-my-2 overflow-x-auto print:overflow-visible sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow border-b print:overflow-visible border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="bg-gray-50 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky-left"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Year
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Form
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Allergies
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  {/* Bookings */}
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Mon
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Tue
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Wed
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Thu
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Fri
                  </th>
                  {/* End Bookings */}
                  <th scope="col" className="relative px-6 py-3 print:hidden">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents.length > 0 ? filteredStudents.slice((page - 1) * PER_PAGE, Math.min(filteredStudents.length, page * PER_PAGE)).map((student, studentIdx) => (
                  <StudentRow
                    key={student.id}
                    student={student}
                    studentIdx={studentIdx}
                    week={week}
                    bookings={bookings}
                    onBook={date => setCreateBooking({ date, student })}
                    onBookingCancelled={onBookingCancelled}
                  />
                )) : (
                  <tr>
                    <td colSpan={10} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      This schools has no students, import some to get started.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* <div className="mt-4 flex justify-end">
          <p className="bg-pink-400 inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs font-medium text-white">
            FSM
          </p>
          <p className="bg-rose-400 ml-2 inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs font-medium text-white">
            UIFSM
          </p>
          <p className="bg-orange-400 ml-2 inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs font-medium text-white">
            Pupil Premium
          </p>
        </div> */}
        </div>
      </div>
      {numPages > 1 && (
        <div className="mt-8">
          <Pagination page={page} numPages={numPages} setPage={setPage} />
        </div>
      )}
      {
        createBooking &&
        <BookingModal
          open
          setOpen={open => {
            if (open) {
              dispatch(fetchBookingsForWeek({ schoolId: school, date: format(week, 'yyyy-MM-dd'), force: true }))
            }
            setCreateBooking(undefined)
          }}
          booking={createBooking}
        />
      }
    </div>
  )
}