import { LockClosedIcon } from '@heroicons/react/solid'
import { useContext, useState } from 'react'
// import { useHistory } from 'react-router-dom'
import { auth } from '../../auth'
import logo from '../../assets/lf-logo.png'
import { useHistory } from 'react-router-dom'
import { NotificationContext } from '../../context/NotificationContext'

export default function Login() {
  const notificationContext = useContext(NotificationContext)
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [resetPassword, setResetPassword] = useState(false)

  const signIn = async () => {
    try {
      await auth.signInWithEmailAndPassword(email, password)
      history.replace('/')
    } catch (error) {
      setError(error.message)
    }
  }

  const doResetPassword = async () => {
    try {
      await auth.sendPasswordResetEmail(email)
      notificationContext.showNotification('success', 'Password reset has been sent, check your email!')
    } catch (error) {
      notificationContext.showNotification('error', 'Unable to reset password at this time, try again later.')
    } finally {
      setResetPassword(false)
    }
  }

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setError(undefined)
    setSubmitting(true)

    if (resetPassword) {
      await doResetPassword()
    } else {
      await signIn()
    }

    setSubmitting(false)
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-56 w-auto absolute top-0 left-0 right-0"
            src={logo}
            alt="Love Food"
          />
          <h2 className="mt-12 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={submit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-brand-green-dark focus:border-brand-green-dark focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </div>
            {!resetPassword && (
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-brand-green-dark focus:border-brand-green-dark focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                />
              </div>
            )}
          </div>

          <div className="flex items-center justify-between">
            {/* <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-brand-green focus:ring-brand-green-dark border-gray-300 rounded"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div> */}

            <div className="ml-auto text-sm">
              <button type="button" className="font-medium text-brand-green hover:opacity-75" onClick={() => setResetPassword(true)} disabled={submitting}>
                Forgot your password?
              </button>
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={submitting}
              // className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-brand-green hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-dark"
              className="group relative w-full flex justify-center brand-button"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-brand-green-dark group-hover:opacity-75" aria-hidden="true" />
              </span>
              {resetPassword ? 'Send Reset Link' : 'Sign in'}
            </button>
            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
          </div>
        </form>
      </div>
    </div>
  )
}
