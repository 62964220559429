import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState, ThunkAPI } from ".";
import api from "../api";
import { Meal } from "../types/meal";

export const ROUTE = '/meals';

type State = {
  meals: Meal[]
}

const initialState: State = {
  meals: []
}

export const fetchMeals = createAsyncThunk<Meal[], void, ThunkAPI>(
  'meals/fetch',
  async (_, thunkAPI) => {
    const response = await api.get(ROUTE)
    return response.data.data
  }
)

export const saveMeal = createAsyncThunk<Meal, Meal, ThunkAPI>(
  'meals/save',
  async (meal, thunkAPI) => {
    let response: AxiosResponse<any>
    if (meal.id) {
      response = await api.put(`${ROUTE}/${meal.id}`, meal)
    } else {
      response = await api.post(ROUTE, meal)
    }

    return response.data.data
  }
)

export const mealSlice = createSlice({
  name: 'meals',
  initialState,
  reducers: {
    pushMeal: (state, action: PayloadAction<Meal>) => {
      const mealIdx = state.meals.findIndex(meal => meal.id === action.payload.id)
      if (mealIdx !== -1) {
        state.meals[mealIdx] = action.payload
      } else {
        state.meals.push(action.payload)
      }
    },
    removeMeal: (state, action: PayloadAction<Meal>) => {
      const mealIdx = state.meals.findIndex(meal => meal.id === action.payload.id)
      if (mealIdx >= 0) {
        state.meals.splice(mealIdx, 1)
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMeals.fulfilled, (state, action) => {
      state.meals = action.payload
    })
    builder.addCase(saveMeal.fulfilled, (state, action) => {
      const mealIdx = state.meals.findIndex(meal => meal.id === action.payload.id)
      if (mealIdx !== -1) {
        state.meals[mealIdx] = action.payload
      } else {
        state.meals.push(action.payload)
      }
    })
  },
})

export const mealSelector = createSelector<RootState, string | undefined, Meal[], string | undefined, Meal | undefined>(
  state => state.meal.meals,
  (_, id) => id,
  (meals, id) => id ? meals.find(meal => meal.id === id) : undefined
)

export const { pushMeal, removeMeal } = mealSlice.actions

export default mealSlice.reducer