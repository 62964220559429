import { useContext, useEffect } from "react"
import { Link } from "react-router-dom"
import { TitleContext } from "../context/TitleContext"
import { useAppSelector } from "../hooks/useRedux"
import { classNames, currencyFormatter } from "../utils"

export default function Meals() {
  const titleContext = useContext(TitleContext)
  const meals = useAppSelector(state => state.meal.meals)

  useEffect(() => {
    titleContext.setTitle('Menus')
  }, [titleContext])

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="mb-4 flex justify-end items-center">
            <Link
              to={{
                pathname: `/meals/create`,
                state: { title: 'Create Meal' },
              }}
              className="print:hidden brand-button flex items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
              </svg>
              Create
            </Link>
          </div>
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Item Code
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {meals.length > 0 ? meals.map((meal, mealIdx) => (
                  <tr key={meal.id} className={mealIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className={classNames(
                      meal.dinner_type === 'allergy' ? 'border-red-500' : 'border-transparent',
                      "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-l-8"
                    )}>
                      {meal.name}
                      <span className="block text-xs font-normal text-gray-500">{meal.id}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{meal.price ? currencyFormatter.format(parseInt(meal.price) / 100) : meal.price}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{meal.item_code}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">{meal.dinner_type}</td>
                    <td className={classNames(
                      meal.dinner_type === 'allergy' ? 'border-red-500' : 'border-transparent',
                      "px-6 py-4 whitespace-nowrap text-right text-sm font-medium border-r-8"
                    )}>
                      <div className="flex justify-end">
                        <Link
                          to={{
                            pathname: `/meals/${meal.id}`,
                            state: { title: meal.name },
                          }}
                          className="text-brand-green underline hover:opacity-75"
                        >
                          Edit
                        </Link>
                      </div>
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={5} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      No meals have been added, create a meal to get started.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
