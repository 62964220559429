import axios, { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import api from "../api";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { NotificationContext } from "../context/NotificationContext";
import { Errors } from "../types/errors";
import BackButton from "./BackButton";
import DeleteModal from "./DeleteModal";
import Input from "./form/Input";
import { TitleContext } from "../context/TitleContext";
import { fetchStaff, pushStaff, removeStaff, ROUTE, staffSelector } from "../store/staffSlice";
import { blankStaff, Staff } from "../types/staff";
import Select from "./form/Select";

const typeOptions = [{ id: 'admin', name: 'Admin' }, { id: 'staff', name: 'Staff' }]

export default function EditStaff(props: RouteComponentProps<{ id?: string }>) {
  const id = props.match.params.id
  const titleContext = useContext(TitleContext)
  const notificationContext = useContext(NotificationContext)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const schools = useAppSelector(state => state.school.schools)
  const actualStaff = useAppSelector(state => staffSelector(state, id))
  const [staff, setStaff] = useState<Staff>(actualStaff || { ...blankStaff })
  const [errors, setErrors] = useState<Errors>({})
  const [saving, setSaving] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  useEffect(() => {
    dispatch(fetchStaff())
  }, [dispatch])

  useEffect(() => {
    if (actualStaff) setStaff(actualStaff)
  }, [actualStaff])

  useEffect(() => {
    if (actualStaff || schools.length <= 0) return
    setStaff(staff => ({ ...staff, schools: [schools[0].id!] }))
  }, [actualStaff, schools])

  useEffect(() => {
    titleContext.setTitle(staff?.email ? staff.email : 'Create Staff Member')
  }, [titleContext, staff])

  useEffect(() => {
    if (staff.type === 'admin') {
      setErrors(errors => ({ ...errors, type: ['Caution: Admin users have full access to the system'] }))
    } else {
      setErrors(errors => ({ ...errors, type: [] }))
    }
  }, [staff])

  const onDelete = async () => {
    if (!staff?.email) return

    try {
      await api.delete(`${ROUTE}/${staff.email}`)
      dispatch(removeStaff(staff))
      history.goBack()
      notificationContext.showNotification('success', 'Staff successfully deleted!')
    } catch (error) {
      notificationContext.showNotification('error', 'Failed to deleted staff.')
    }
  }

  const save = async () => {
    setSaving(true)
    setErrors({})

    let response: AxiosResponse<any>
    try {
      if (actualStaff?.email) {
        response = await api.put(`${ROUTE}/${actualStaff?.email}`, staff)
      } else {
        response = await api.post(ROUTE, staff)
      }

      const data = response.data.data
      dispatch(pushStaff(data))
      notificationContext.showNotification('success', 'Staff successfully saved!')
      history.replace(`/staff`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          setErrors(error.response?.data.errors)
        }
      }

      notificationContext.showNotification('error', 'Failed to save staff.')
    }

    setSaving(false)
  }

  const getError = (field: string) => {
    if (errors[field]) return errors[field][0]
  }

  return (
    <div className="max-w-4xl flex flex-col mx-auto">
      <BackButton className="mr-auto" />
      <form className="text-left space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            <div>
              <h2 className="text-xl font-bold font-cursive text-brand-green-dark">Staff Information</h2>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <Input
                  label="Email"
                  error={getError('email')}
                  type="email"
                  name="email"
                  id="email"
                  value={staff.email}
                  onChange={event => setStaff(staff => ({ ...staff, email: event.target.value }))}
                />
              </div>
              <div className="sm:col-span-2">
                <Select
                  label="Type"
                  options={typeOptions}
                  selectedId={staff.type}
                  onChange={selected => setStaff(staff => ({ ...staff, type: selected?.id! }))}
                  error={getError('type')}
                />
              </div>
              <div className="sm:col-span-2">
                <Select
                  label="School"
                  options={[
                    {id: undefined, name: "Please Select"},
                    ...schools.map(school => ({ id: school.id!, name: school.name }))
                  ]}
                  selectedId={staff.schools![0]}
                  onChange={
                    selected => selected ? setStaff(staff => ({ ...staff, schools: [selected.id!] })) : undefined
                  }
                  error={getError('schoolId')}
                />
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <Input
                  label="Name"
                  error={getError('displayName')}
                  type="text"
                  name="displayName"
                  id="displayName"
                  value={staff.displayName}
                  onChange={event => setStaff(staff => ({ ...staff, displayName: event.target.value }))}
                />
              </div>
              {!actualStaff && (
                <>
                  <div className="sm:col-span-2">
                    <Input
                      label="Password"
                      error={getError('password')}
                      type="password"
                      name="password"
                      id="password"
                      value={staff.password}
                      onChange={event => setStaff(staff => ({ ...staff, password: event.target.value }))}
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <Input
                      label="Password Confirmation"
                      error={getError('password')}
                      type="password"
                      name="password_confirmation"
                      id="password_confirmation"
                      value={staff.password_confirmation}
                      onChange={event => setStaff(staff => ({ ...staff, password_confirmation: event.target.value }))}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="brand-button-tertiary"
              // className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => history.goBack()}
              disabled={saving}
            >
              Cancel
            </button>
            {
              staff.email &&
              <button
                type="button"
                className="ml-3 brand-button-secondary bg-red-100 hover:bg-red-50 text-red-600"
                onClick={() => setDeleteOpen(true)}
                disabled={saving}
              >
                Delete
              </button>
            }
            <button
              type="submit"
              className="ml-3 brand-button"
              //className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={save}
              disabled={saving}
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <DeleteModal open={deleteOpen} setOpen={setDeleteOpen} onDelete={onDelete} />
    </div>
  )
}
