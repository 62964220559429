import { Fragment, useContext, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import Input from './form/Input'
import api from '../api'
import { NotificationContext } from '../context/NotificationContext'
import axios from 'axios'
import { Errors } from '../types/errors'
import { useEffect } from 'react'

type ChangePasswordProps = {
  open: boolean
  setOpen: (open: boolean) => void
}

export default function ChangePasswordModal({ open, setOpen }: ChangePasswordProps) {
  const notificationContext = useContext(NotificationContext)
  const cancelButtonRef = useRef(null)
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState<Errors>({})
  const [message, setMessage] = useState<string>()

  useEffect(() => {
    if (password.length >= 6) {
      setErrors({})
    }

    if (passwordConfirmation.length > 0 && password !== passwordConfirmation) {
      setErrors(errors => ({ ...errors, 'password_confirmation': ['Passwords do not match'] }))
    } else {
      setErrors(errors => ({ ...errors, 'password_confirmation': [] }))
    }
  }, [password, passwordConfirmation])

  const onSave = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSaving(true)
    setErrors({})
    setMessage('')

    try {
      await api.post('/password', { password, password_confirmation: passwordConfirmation })
      notificationContext.showNotification('success', 'Password successfully changed!')
      setSaving(false)
      setOpen(false)
      setPassword('')
      setPasswordConfirmation('')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          setErrors(error.response?.data.errors)
          // setMessage(error.response?.data.message)
        }
      }

      notificationContext.showNotification('error', 'Failed to change password.')
      setSaving(false)
    }
  }

  const close = () => {
    setPassword('')
    setPasswordConfirmation('')
    setOpen(false)
  }

  const getError = (field: string) => {
    if (errors[field]) return errors[field][0]
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:max-w-2xl sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-dark"
                  onClick={() => close()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="h-full sm:flex sm:items-start">
                <div className="h-full flex flex-col flex-grow mt-3 sm:mt-0 text-center sm:text-left">
                  <Dialog.Title as="h2" className="text-xl font-bold font-cursive text-brand-green-dark">
                    Change your password.
                  </Dialog.Title>
                  {message && <p className="mt-2 text-sm text-red-600">{message}</p>}
                  <form className="text-left space-y-8 divide-y divide-gray-200" onSubmit={onSave}>
                    <div className="space-y-8 divide-y divide-gray-200">
                      <div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-3">
                            <Input
                              label="Password"
                              type="password"
                              value={password}
                              onChange={e => setPassword(e.target.value)}
                              error={getError('password')}
                            />
                          </div>
                          <div className="sm:col-span-3">
                            <Input
                              label="Confirm Password"
                              type="password"
                              value={passwordConfirmation}
                              onChange={e => setPasswordConfirmation(e.target.value)}
                              error={getError('password_confirmation')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="mt-5 pt-5 border-t">
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="brand-button-tertiary"
                        onClick={() => close()}
                        disabled={saving}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="ml-3 brand-button"
                        disabled={saving}
                        onClick={onSave}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
