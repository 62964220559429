import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { classNames } from "../../utils";

export type InputProps = {
  label: string
  hint?: string
  error?: string
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export default function Input({label, hint, error, ...props}: InputProps) {
  return (
    <>
      <label
        htmlFor={props.name}
        className={classNames(
          error ? 'text-red-600' : 'text-gray-600 focus-within:text-brand-green-dark',
          'block text-sm font-medium'
        )}
      >
        {label}
        <div className="mt-1">
          <input
            {...props}
            className={classNames(
              error ? 'focus:ring-red-600 focus:border-red-600 border-red-400 hover:border-red-600' : 'focus:ring-brand-green-dark focus:border-brand-green-dark border-gray-300 hover:border-gray-400',
              'shadow-sm text-gray-800 block w-full sm:text-sm rounded-md placeholder-gray-300'
            )}
          />
        </div>
        {hint && <p className="mt-2 text-xs text-gray-400">{hint}</p>}
      </label>
      {
        error &&
        <p className="mt-2 text-sm text-red-600" id={props.id ? `${props.id}-error` : undefined}>
          {error}
        </p>
      }
    </>
  )
}