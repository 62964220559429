import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type State = {
  authenticated: boolean
  type: string
  schools: string[]
}

const initialState: State = {
  authenticated: false,
  type: '',
  schools: [],
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.authenticated = action.payload
    },
    setType: (state, action: PayloadAction<string>) => {
      state.type = action.payload
    },
    setSchools: (state, action: PayloadAction<string[]>) => {
      state.schools = action.payload
    },
    setAuthState: (state, action: PayloadAction<State>) => {
      return action.payload
    },
    resetAuthState: () => {
      return initialState
    }
  },
})

export const { setAuthenticated, setType, setSchools, setAuthState, resetAuthState } = authSlice.actions

export default authSlice.reducer