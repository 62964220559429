import { format, parseISO } from "date-fns"
import { useState } from "react"
import { useContext, useEffect } from "react"
import { TitleContext } from "../context/TitleContext"
import { useAppDispatch, useAppSelector } from "../hooks/useRedux"
import { fetchImports } from "../store/importSlice"
import { Import } from "../types/import"
import { classNames } from "../utils"
import ImportFailedModel from "./ImportFailedModal"

export default function Imports() {
  const titleContext = useContext(TitleContext)
  const dispatch = useAppDispatch()
  const imports = useAppSelector(state => state.import.imports)
  const schools = useAppSelector(state => state.school.schools)
  const [selectedImport, setSelectedImport] = useState<Import>()

  useEffect(() => {
    dispatch(fetchImports())
  }, [dispatch])

  useEffect(() => {
    titleContext.setTitle('Imports')
  }, [titleContext])

  const schoolById = (id: string) => schools.find(s => s.id === id)

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    School
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Updated
                  </th>
                </tr>
              </thead>
              <tbody>
                {imports.length > 0 ? imports.map((i, importIdx) => (
                  <tr key={i.id} className={importIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {schoolById(i.schoolId)?.name}
                      <span className="block text-xs font-normal text-gray-500">{i.id}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                      {/* {i.status} */}
                      <button
                        className={classNames(
                          "inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs font-medium text-white capitalize",
                          i.status === 'failed' ? 'bg-red-600 hover:bg-opacity-60 cursor-pointer' : 'bg-brand-green cursor-default'
                        )}
                        onClick={i.status === 'failed' ? () => setSelectedImport(i) : undefined}
                      >
                        {i.status}
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">{format(parseISO(i.created_at), 'dd/MM/yyyy HH:mm')}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">{format(parseISO(i.updated_at), 'dd/MM/yyyy HH:mm')}</td>
                  </tr>
                )) : (
                  <tr className="bg-white">
                    <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      You have no active imports
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedImport && <ImportFailedModel
        open
        setOpen={open => setSelectedImport(undefined)}
        import={selectedImport}
      />}
    </div>
  )
}
