import axios, { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import api from "../api";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { pushSchool, removeSchool, ROUTE, schoolSelector } from "../store/schoolSlice";
import { NotificationContext } from "../context/NotificationContext";
import { Errors } from "../types/errors";
import { blankSchool } from "../types/school";
import BackButton from "./BackButton";
import DeleteModal from "./DeleteModal";
import Input from "./form/Input";
import { TitleContext } from "../context/TitleContext";
import Checkbox from "./form/Checkbox";

export default function School(props: RouteComponentProps<{ id?: string }>) {
  const id = props.match.params.id
  const titleContext = useContext(TitleContext)
  const notificationContext = useContext(NotificationContext)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const schools = useAppSelector(state => state.school.schools)
  const school = useAppSelector(state => schoolSelector(state, id)) || blankSchool
  const [name, setName] = useState(school.name)
  const [number, setNumber] = useState(school.schoolNumber)
  const [addressLine1, setAddressLine1] = useState(school.address.line_1)
  const [addressLine2, setAddressLine2] = useState(school.address.line_2)
  const [city, setCity] = useState(school.address.city)
  const [postcode, setPostcode] = useState(school.address.postcode)
  const [contactName, setContactName] = useState(school.contact.name)
  const [contactEmail, setContactEmail] = useState(school.contact.email)
  const [contactPhone, setContactPhone] = useState(school.contact.phone)
  const [billingFsm, setBillingFsm] = useState(school.billing?.fsm ?? true)
  const [billingUifsm, setBillingUifsm] = useState(school.billing?.uifsm ?? true)
  const [billingPupilPremium, setBillingPupilPremium] = useState(school.billing?.pupil_premium ?? false)
  const [ratesPaid, setRatesPaid] = useState(school.rates?.paid)
  const [ratesFsm, setRatesFsm] = useState(school.rates?.fsm)
  const [ratesUifsm, setRatesUifsm] = useState(school.rates?.uifsm)
  const [ratesPupilPremium, setRatesPupilPremium] = useState(school.rates?.pupil_premium)
  const [errors, setErrors] = useState<Errors>({})
  const [saving, setSaving] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  useEffect(() => {
    if (!school.id && id !== 'create') {
      history.replace('/')
    }
  }, [history, id, school])

  useEffect(() => {
    titleContext.setTitle(school.id ? school.name : 'Create School')
  }, [titleContext, school])

  useEffect(() => {
    setName(school.name)
    setNumber(school.schoolNumber)
    setAddressLine1(school.address.line_1)
    setAddressLine2(school.address.line_2)
    setCity(school.address.city)
    setPostcode(school.address.postcode)
    setContactName(school.contact.name)
    setContactEmail(school.contact.email)
    setContactPhone(school.contact.phone)
    if (school.billing?.fsm) {
        setBillingFsm(school.billing?.fsm)
    }
    if (school.billing?.uifsm) {
      setBillingFsm(school.billing?.uifsm)
    }
    if (school.billing?.pupil_premium) {
      setBillingFsm(school.billing?.pupil_premium)
    }
    setRatesFsm(school.rates?.fsm)
    setRatesUifsm(school.rates?.uifsm)
    setRatesPupilPremium(school.rates?.pupil_premium)
  }, [schools, school])

  const onDelete = async () => {
    if (!school.id) return

    try {
      await api.delete(`${ROUTE}/${school.id}`)
      dispatch(removeSchool(school))
      history.goBack()
      notificationContext.showNotification('success', 'School successfully deleted!')
    } catch (error) {
      notificationContext.showNotification('error', 'Failed to deleted school.')
    }
  }

  const save = async () => {
    setSaving(true)
    setErrors({})

    const payload = {
      id: school?.id,
      name: name,
      schoolNumber: number,
      address: {
        line_1: addressLine1,
        line_2: addressLine2,
        city: city,
        postcode: postcode,
      },
      contact: {
        name: contactName,
        email: contactEmail,
        phone: contactPhone,
      },
      billing: {
        fsm: billingFsm,
        uifsm: billingUifsm,
        pupil_premium: billingPupilPremium
      },
      rates: {
        paid: ratesPaid,
        fsm: ratesFsm,
        uifsm: ratesUifsm,
        pupil_premium: ratesPupilPremium
      },
    }

    let response: AxiosResponse<any>
    try {
      if (payload.id) {
        response = await api.put(`${ROUTE}/${school.id}`, payload)
      } else {
        response = await api.post(ROUTE, payload)
      }

      const data = response.data.data
      dispatch(pushSchool(data))
      notificationContext.showNotification('success', 'School successfully saved!')
      history.replace(`/${data.id}`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          setErrors(error.response?.data.errors)
        }
      }

      notificationContext.showNotification('error', 'Failed to save school.')
    }

    setSaving(false)
  }

  const getError = (field: string) => {
    if (errors[field]) return errors[field][0]
  }

  return (
    <div className="max-w-4xl flex flex-col mx-auto">
      <BackButton className="mr-auto" />
      <form className="text-left space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            <div>
              <h2 className="text-xl font-bold font-cursive text-brand-green-dark">School Information</h2>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <Input
                  label="Name"
                  error={getError('name')}
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={event => setName(event.target.value)}
                />
              </div>

              <div className="sm:col-span-2">
                <Input
                  label="School Number"
                  error={getError('schoolNumber')}
                  type="text"
                  name="number"
                  id="number"
                  value={number}
                  onChange={event => setNumber(event.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="pt-8">
            <div>
              <h2 className="text-xl font-bold font-cursive text-brand-green-dark">Address Information</h2>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <Input
                  label="Address Line 1"
                  error={getError('address.line_1')}
                  type="text"
                  name="address-line1"
                  id="address-line1"
                  value={addressLine1}
                  onChange={event => setAddressLine1(event.target.value)}
                  autoComplete="address-line1"
                />
              </div>

              <div className="sm:col-span-3">
                <Input
                  label="Address Line 2"
                  error={getError('address.line_2')}
                  type="text"
                  name="address-line2"
                  id="address-line2"
                  value={addressLine2}
                  onChange={event => setAddressLine2(event.target.value)}
                  autoComplete="address-line2"
                />
              </div>

              <div className="sm:col-span-3">
                <Input
                  label="City"
                  error={getError('address.city')}
                  type="text"
                  name="city"
                  id="city"
                  value={city}
                  onChange={event => setCity(event.target.value)}
                  autoComplete="address-level2"
                />
              </div>

              <div className="sm:col-span-2">
                <Input
                  label="Postcode"
                  error={getError('address.postcode')}
                  type="text"
                  name="postcode"
                  id="postcode"
                  value={postcode}
                  onChange={event => setPostcode(event.target.value)}
                  autoComplete="postal-code"
                />
              </div>
            </div>
          </div>

          <div className="pt-8">
            <div>
              <h2 className="text-xl font-bold font-cursive text-brand-green-dark">Contact Information</h2>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <Input
                  label="Name"
                  error={getError('contact.name')}
                  type="text"
                  name="contactName"
                  id="contactName"
                  value={contactName}
                  onChange={event => setContactName(event.target.value)}
                  autoComplete="given-name"
                />
              </div>

              <div className="sm:col-span-2">
                <Input
                  label="Email"
                  error={getError('contact.email')}
                  type="email"
                  name="email"
                  id="email"
                  value={contactEmail}
                  onChange={event => setContactEmail(event.target.value)}
                  autoComplete="email"
                />
              </div>

              <div className="sm:col-span-2">
                <Input
                  label="Phone"
                  error={getError('contact.phone')}
                  type="tel"
                  name="contactNumber"
                  id="contactNumber"
                  value={contactPhone}
                  onChange={event => setContactPhone(event.target.value)}
                  autoComplete="tel"
                />
              </div>
            </div>
          </div>
          <div className="pt-8">
            <div>
              <h2 className="text-xl font-bold font-cursive text-brand-green-dark">Billing &amp; Rates</h2>
              <p>Shows or hides sections of the school billing report and customises the school rate.</p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                  <Input
                    label="Paid Meal Rates"
                    hint="Value in £ (GBP)"
                    error={getError('rates.paid')}
                    type="text"
                    placeholder="2.30"
                    name="ratesPaid"
                    id="ratesPaid"
                    value={ratesPaid}
                    onChange={event => setRatesPaid(event.target.value)}
                    onBlur={event => setRatesPaid((!isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value).toFixed(2):""))}
                  />
              </div>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <Checkbox
                  label="FSM Billing"
                  error={getError('billing.fsm')}
                  name="billingFSM"
                  id="billingFSM"
                  checked={billingFsm}
                  onChange={event => setBillingFsm(event.target.checked)}
                />
              </div>
              <div className="sm:col-span-2">
                <Checkbox
                  label="UIFSM Billing"
                  error={getError('billing.uifsm')}
                  name="billingUIFSM"
                  id="billingUIFSM"
                  checked={billingUifsm}
                  onChange={event => setBillingUifsm(event.target.checked)}
                />
              </div>
              <div className="sm:col-span-2">
                <Checkbox
                  label="Pupil Premium Billing"
                  error={getError('billing.pupil_premium')}
                  name="billingPupilPremium"
                  id="billingPupilPremium"
                  checked={billingPupilPremium}
                  onChange={event => setBillingPupilPremium(event.target.checked)}
                />
              </div>
            </div>
            {(billingFsm || billingPupilPremium || billingFsm) && 
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                {billingFsm && <div className="sm:col-span-2">
                  <Input
                    label="FSM Rates"
                    hint="Value in £ (GBP)"
                    error={getError('rates.fsm')}
                    type="text"
                    placeholder="2.30"
                    name="ratesFsm"
                    id="ratesFsm"
                    value={ratesFsm}
                    onChange={event => setRatesFsm(event.target.value)}
                    onBlur={event => setRatesFsm((!isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value).toFixed(2) : ""))}
                  />
                </div>
                }
                {billingUifsm && <div className="sm:col-span-2">
                  <Input
                    label="UIFSM Rates"
                    hint="Value in £ (GBP)"
                    error={getError('rates.uifsm')}
                    type="text"
                    placeholder="2.30"
                    name="ratesUiFsm"
                    id="ratesUiFsm"
                    value={ratesUifsm}
                    onChange={event => setRatesUifsm(event.target.value)}
                    onBlur={event => setRatesUifsm((!isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value).toFixed(2) : ""))}
                  />
                </div>
                }
                {billingPupilPremium && <div className="sm:col-span-2">
                  <Input
                    label="Pupil Premium Rates"
                    hint="Value in £ (GBP)"
                    error={getError('rates.uifsm')}
                    type="text"
                    placeholder="2.30"
                    name="ratesPupilPremium"
                    id="ratesPupilPremium"
                    value={ratesPupilPremium}
                    onChange={event => setRatesPupilPremium(event.target.value)}
                    onBlur={event => setRatesPupilPremium((!isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value).toFixed(2) : ""))}
                  />
                </div>
                }
              </div>
            }
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="brand-button-tertiary"
              // className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => history.goBack()}
              disabled={saving}
            >
              Cancel
            </button>
            {
              school.id &&
              <button
                type="button"
                className="ml-3 brand-button-secondary bg-red-100 hover:bg-red-50 text-red-600"
                onClick={() => setDeleteOpen(true)}
                disabled={saving}
              >
                Delete
              </button>
            }
            <button
              type="submit"
              className="ml-3 brand-button"
              //className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={save}
              disabled={saving}
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <DeleteModal open={deleteOpen} setOpen={setDeleteOpen} onDelete={onDelete} />
    </div>
  )
}
