import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState, ThunkAPI } from ".";
import api from "../api";
import { School } from "../types/school";

export const ROUTE = '/schools';

type State = {
  schools: School[]
}

const initialState: State = {
  schools: []
}

export const fetchSchools = createAsyncThunk<School[], void, ThunkAPI>(
  'schools/fetch',
  async (_, thunkAPI) => {
    const response = await api.get(ROUTE)
    return response.data.data
  }
)

export const saveSchool = createAsyncThunk<School, School, ThunkAPI>(
  'schools/save',
  async (school, thunkAPI) => {
    let response: AxiosResponse<any>
    if (school.id) {
      response = await api.put(`${ROUTE}/${school.id}`, school)
    } else {
      response = await api.post(ROUTE, school)
    }

    return response.data.data
  }
)

export const schoolSlice = createSlice({
  name: 'schools',
  initialState,
  reducers: {
    pushSchool: (state, action: PayloadAction<School>) => {
      const schoolIdx = state.schools.findIndex(school => school.id === action.payload.id)
      if (schoolIdx !== -1) {
        state.schools[schoolIdx] = action.payload
      } else {
        state.schools.push(action.payload)
      }
    },
    removeSchool: (state, action: PayloadAction<School>) => {
      const schoolIdx = state.schools.findIndex(school => school.id === action.payload.id)
      if (schoolIdx >= 0) {
        state.schools.splice(schoolIdx, 1)
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSchools.fulfilled, (state, action) => {
      state.schools = action.payload
    })
    builder.addCase(saveSchool.fulfilled, (state, action) => {
      const schoolIdx = state.schools.findIndex(school => school.id === action.payload.id)
      if (schoolIdx !== -1) {
        state.schools[schoolIdx] = action.payload
      } else {
        state.schools.push(action.payload)
      }
    })
  },
})

export const schoolSelector = createSelector<RootState, string | undefined, School[], string | undefined, School | undefined>(
  state => state.school.schools,
  (_, id) => id,
  (schools, id) => id ? schools.find(school => school.id === id) : undefined
)

export const { pushSchool, removeSchool } = schoolSlice.actions

export default schoolSlice.reducer