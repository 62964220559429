import React, { Fragment, useContext, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { schoolSelector } from '../store/schoolSlice'
import { classNames } from '../utils'
import { XIcon } from '@heroicons/react/outline'
import api from '../api'
import axios from 'axios'
import { fetchStudents } from '../store/studentSlice'
import { NotificationContext } from '../context/NotificationContext'

type ImportModalProps = {
  schoolId: string,
  open: boolean,
  setOpen: (open: boolean) => void
}

export default function ImportModal({ schoolId, open, setOpen }: ImportModalProps) {
  const notificationContext = useContext(NotificationContext)
  const dispatch = useAppDispatch()
  const cancelButtonRef = useRef(null)
  const school = useAppSelector(state => schoolSelector(state, schoolId))
  const [file, setFile] = useState<File>()
  const [dragging, setDragging] = useState(false)
  const [importing, setImporting] = useState(false)
  const [uploadPercent, setUploadPercent] = useState(0)

  const stopEvent = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onDragEnter = (e: React.DragEvent) => {
    stopEvent(e)
    setDragging(true)
  }

  const onDragLeave = (e: React.DragEvent) => {
    stopEvent(e)
    setDragging(false)
  }

  const onDrop = (e: React.DragEvent) => {
    stopEvent(e)
    setDragging(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setFile(e.dataTransfer.files[0])
    }
  }

  const onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      setFile(e.currentTarget.files[0])
    }
  }

  const reset = () => {
    setImporting(false)
    setUploadPercent(0)
    setFile(undefined)
  }

  const onImport = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!file) return
    setImporting(true)

    try {
      const extension = file.name.split('.').pop() || 'csv'
      const signedUrl = await api.post('/signed-url', { extension })
      const headers = signedUrl.data.headers
      if ('Host' in headers) {
        delete headers.Host
      }

      await axios.put(signedUrl.data.url, file, {
        headers,
        onUploadProgress: (e: ProgressEvent) => {
          setUploadPercent(Math.ceil(e.loaded / e.total * 100))
        }
      })

      await api.post(`/import-students`, { school: schoolId, fileKey: signedUrl.data.key })

      notificationContext.showNotification('success', 'Import successfully started!')
      dispatch(fetchStudents())
      setOpen(false)
    } catch (error) {
      notificationContext.showNotification('error', 'Unable to import students at this time.')
    }

    reset()
  }

  const onCancel = () => {
    reset()
    setOpen(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <form className="space-y-8 divide-y divide-gray-200" onSubmit={onImport}>
                <div className="space-y-8 divide-y divide-gray-200">
                  <div>
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Import</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Import new students into {school?.name}
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        <div
                          className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                          onDragEnter={onDragEnter}
                          onDragLeave={onDragLeave}
                          onDragOver={stopEvent}
                          onDrop={onDrop}
                        >
                          <div className="space-y-1 text-center">
                            {
                              !dragging && file ? (
                                <div className="relative flex items-center justify-center text-sm text-gray-600 border w-48 h-32 p-3 rounded-lg">
                                  <div className="hidden sm:block absolute top-0 right-0 pt-1 pr-1">
                                    <button
                                      type="button"
                                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                                      onClick={() => setFile(undefined)}
                                    >
                                      <span className="sr-only">Remove File</span>
                                      <XIcon className="h-4 w-4" aria-hidden="true" />
                                    </button>
                                  </div>
                                  <p>{file.name}</p>
                                </div>
                              ) : (
                                <>
                                  <svg
                                    // className="mx-auto h-12 w-12 text-gray-400"
                                    className={classNames(
                                      dragging ? 'animate-bounce' : '',
                                      "mx-auto h-12 w-12 text-gray-400"
                                    )}
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <div className="flex text-sm text-gray-600">
                                    <label
                                      htmlFor="file-upload"
                                      className="relative cursor-pointer bg-white rounded-md font-medium text-brand-green underline hover:opacity-60 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-brand-green"
                                    >
                                      <span>Upload a file</span>
                                      <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={onFileChanged} accept=".csv,.xls,.xlsx" />
                                    </label>
                                    <p className="pl-1">or drag and drop</p>
                                  </div>
                                  <p className="text-xs text-gray-500">CSV, XLS, XLSX</p>
                                </>
                              )
                            }
                          </div>
                        </div>
                      </div>

                      {importing && (
                        <div className="sm:col-span-6">
                          <div className="relative pt-1">
                            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-brand-green bg-opacity-60">
                              <div style={{ width: `${uploadPercent}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-brand-green-dark" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="brand-button-tertiary outline-none"
                      ref={cancelButtonRef}
                      disabled={importing}
                      onClick={onCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="ml-3 brand-button"
                      disabled={importing}
                    >
                      Import
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
