import { format, parse } from "date-fns"
import { useCallback, useEffect } from "react"
import { useContext, useState } from "react"
import { RouteComponentProps, useLocation } from "react-router-dom"
import api from "../api"
import { TitleContext } from "../context/TitleContext"
import { useAppSelector } from "../hooks/useRedux"
import useSchoolQuery from "../hooks/useSchoolQuery"
import { schoolSelector } from "../store/schoolSlice"
import { classNames, currencyFormatter } from "../utils"
import BackButton from "./BackButton"

type Header = {
  key: string
  displayName: string
}

type Row = {
  [key: string]: string
}

export default function WeeklyBillingReport(props: RouteComponentProps<{ id: string }>) {
  const id = props.match.params.id
  const titleContext = useContext(TitleContext)
  const schools = useAppSelector(state => state.auth.schools)
  const schoolQuery = useSchoolQuery()
  const [schoolId, setSchoolId] = useState(schoolQuery)
  const school = useAppSelector(state => schoolSelector(state, schoolId))
  const [loading, setLoading] = useState(true)
  const [week, setWeek] = useState<string>()
  const [headers, setHeaders] = useState<Header[]>()
  const [rows, setRows] = useState<Row[]>()

  const search = useLocation().search
  const [date, setDate] = useState<string>()
  useEffect(() => {
    const query = new URLSearchParams(search)
    setDate(query.get('date') ?? undefined)
  }, [search])

  const fetchReport = useCallback(async () => {
    if (!schoolId) return

    setLoading(true)

    const params = week
      ? { school: schoolId, week, type: 'week', date: date }
      : { school: schoolId, month: id, type: 'month', date: date }

    try {
      const response = await api.get('/billing-report', { params })
      setHeaders(response.data.headers)
      setRows(response.data.rows)
    } catch (error) {
      console.log('failed to load report', error)
    } finally {
      setLoading(false)
    }
  }, [id, week, date, schoolId])

  useEffect(() => {
    let school
    if (schools.length > 0) {
      school = schools[0]
    } else {
      school = schoolQuery
    }

    setSchoolId(school)
  }, [fetchReport, schoolQuery, schools])

  useEffect(() => {
    titleContext.setTitle(
      school ? `${school.name} - Weekly Billing Report` : 'Weekly Billing Report'
    )
  }, [titleContext, school])

  useEffect(() => {
    fetchReport()
  }, [fetchReport])

  const getRow = (row: Row, key: string, idx: number) => {
    if (idx === 0 && !week) {
      return (
        <button
          onClick={() => {
            setRows([])
            setWeek(row[key])
          }}
          className="text-brand-green underline hover:opacity-75 print:text-black"
        >
          {format(parse(row[key], 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}
        </button>
      )
    }

    if (key === 'total_cost') {
      return currencyFormatter.format(parseInt(row[key]) / 100)
    }

    return row[key]
  }

  return (
    <div className="flex flex-col">
      <div className="mb-4 flex justify-end">
        <BackButton
          className="print:hidden mr-auto"
          onClick={week ? () => {
            setRows([])
            setWeek(undefined)
          } : undefined}
        />
        <button className="print:hidden brand-button flex items-center" onClick={() => { window.print() }}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
          </svg>
          Print
        </button>
      </div>
      <div className="-my-2 overflow-x-auto overflow-y-visible sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg print:rounded-none print:shadow-none print:border-none">
            <table className="min-w-full divide-y divide-gray-200 text-left">
              <thead className="bg-gray-50">
                <tr>
                  {headers?.map((header, headerIdx) => (
                    <th
                      key={headerIdx}
                      scope="col"
                      className="px-6 py-3 print:p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {header.displayName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  rows && rows.length > 0 ? (
                    rows?.map((row, rowIdx) => (
                      <>
                        <tr key={rowIdx} className={[rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50', 'print:border-b print:border-l print:border-r'].join(' ')}>
                          {
                            headers?.map((header, headerIdx) => (
                              <td key={headerIdx} className={classNames(
                                "px-6 py-4 print:p-2 whitespace-nowrap print:whitespace-normal text-sm text-gray-500",
                                headerIdx === 0 ? 'font-medium' : undefined
                              )}>
                                {getRow(row, header.key, headerIdx)}
                              </td>
                            ))
                          }
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr className="bg-white">
                      <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {loading ? 'Loading...' : 'No Billing information available for this school.'}
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div >
  )
}
