import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../api";
import { NotificationContext } from "../context/NotificationContext";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { removeStudent } from "../store/studentSlice";
import { Errors } from "../types/errors";
import { Student } from "../types/student";
import DeleteModal from "./DeleteModal";
import Checkbox from "./form/Checkbox";
import Input from "./form/Input";
import Select from "./form/Select";
import Textarea from "./form/Textarea";

const years = [
  { id: 'teachers', name: 'Teacher' },
  { id: 'nursery', name: 'Nursery' },
  { id: 'reception', name: 'Reception' },
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
]

export type StudentInformationProps = {
  student: Student
  errors: Errors
  saving: boolean
  onFieldChange: (field: string, value: any) => void
  onSave: () => void
}

export default function StudentInformation({ student, errors, saving, onFieldChange, onSave }: StudentInformationProps) {
  const notificationContext = useContext(NotificationContext)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const schools = useAppSelector(state => state.school.schools)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const getError = (field: string) => {
    if (errors[field]) return errors[field][0]
  }

  const onDelete = async () => {
    try {
      await api.delete(`/students/${student.id}`)
      dispatch(removeStudent(student))
      history.goBack()
      notificationContext.showNotification('success', 'Student successfully deleted!')
    } catch (error) {
      notificationContext.showNotification('error', 'Failed to delete student.')
    }
  }

  return (
    <>
      <form className="text-left space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            <div>
              <h2 className="text-xl font-bold font-cursive text-brand-green-dark">Student Information</h2>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <Select
                  label="School"
                  options={schools.map(school => ({ id: school.id!, name: school.name }))}
                  selectedId={student.schoolId}
                  onChange={selected => onFieldChange('schoolId', selected?.id)}
                  error={getError('schoolId')}
                />
              </div>

              <div className="sm:col-span-2">
                <Input
                  label="Forename"
                  error={getError('forename')}
                  type="text"
                  name="forename"
                  id="forename"
                  value={student.forename}
                  onChange={event => onFieldChange('forename', event.target.value)}
                />
              </div>

              <div className="sm:col-span-2">
                <Input
                  label="Surname"
                  error={getError('surname')}
                  type="text"
                  name="surname"
                  id="surname"
                  value={student.surname}
                  onChange={event => onFieldChange('surname', event.target.value)}
                />
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <Select
                  label="Year"
                  options={years}
                  selectedId={student.year}
                  onChange={selected => onFieldChange('year', selected?.id)}
                  error={getError('year')}
                />
              </div>

              <div className="sm:col-span-2">
                <Input
                  label="Form"
                  error={getError('form')}
                  type="text"
                  name="form"
                  id="form"
                  value={student.form}
                  onChange={event => onFieldChange('form', event.target.value)}
                />
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <Checkbox
                  label="Admission Status"
                  error={getError('admission_status')}
                  name="admission_status"
                  id="admission_status"
                  checked={student.admission_status}
                  onChange={event => onFieldChange('admission_status', event.target.checked)}
                />
              </div>

              <div className="sm:col-span-4">
                <Checkbox
                  label="FSM"
                  error={getError('fsm')}
                  name="fsm"
                  id="fsm"
                  checked={student.fsm}
                  onChange={event => onFieldChange('fsm', event.target.checked)}
                />

                <Checkbox
                  label="UIFSM"
                  error={getError('uifsm')}
                  name="uifsm"
                  id="uifsm"
                  checked={student.uifsm}
                  onChange={event => onFieldChange('uifsm', event.target.checked)}
                />

                <Checkbox
                  label="Pupil Premium"
                  error={getError('premium')}
                  name="premium"
                  id="premium"
                  checked={student.premium}
                  onChange={event => onFieldChange('premium', event.target.checked)}
                />
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <Textarea
                  label="Dietary Information"
                  error={getError('dietary')}
                  name="dietary"
                  id="dietary"
                  value={student.dietary}
                  onChange={event => onFieldChange('dietary', event.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="brand-button-tertiary"
              // className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => history.goBack()}
              disabled={saving}
            >
              Cancel
            </button>
            <button
              type="button"
              className="ml-3 brand-button-secondary bg-red-100 hover:bg-red-50 text-red-600"
              onClick={() => setDeleteOpen(true)}
              disabled={saving}
            >
              Delete
            </button>
            <button
              type="submit"
              className="ml-3 brand-button"
              //className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={onSave}
              disabled={saving}
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <DeleteModal open={deleteOpen} setOpen={setDeleteOpen} onDelete={onDelete} />
    </>
  )
}