import { DetailedHTMLProps, TextareaHTMLAttributes } from "react";
import { classNames } from "../../utils";

export type TextareaProps = {
  label: string
  error: string | undefined
} & DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>

export default function Textarea({ label, error, ...props }: TextareaProps) {
  return (
    <>
      <label
        htmlFor={props.name}
        className={classNames(
          error ? 'text-red-600' : 'text-gray-600 focus-within:text-brand-green-dark',
          'block text-sm font-medium'
        )}
      >
        {label}
        <div className="mt-1">
          <textarea
            {...props}
            className="text-gray-800 shadow-sm block w-full focus:ring-brand-green-dark focus:border-brand-green-dark sm:text-sm border border-gray-300 rounded-md" />
        </div>
      </label>
      {
        error &&
        <p className="mt-2 text-sm text-red-600" id={props.id ? `${props.id}-error` : undefined}>
          {error}
        </p>
      }
    </>
  )
}