import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState, ThunkAPI } from ".";
import api from "../api";
import { Menu } from "../types/menu";

export const ROUTE = '/menus';

type State = {
  menus: Menu[]
}

const initialState: State = {
  menus: []
}

export const fetchMenus = createAsyncThunk<Menu[], void, ThunkAPI>(
  'menus/fetch',
  async (_, thunkAPI) => {
    const response = await api.get(ROUTE)
    return response.data.data
  }
)

export const saveMenu = createAsyncThunk<Menu, Menu, ThunkAPI>(
  'menus/save',
  async (menu, thunkAPI) => {
    let response: AxiosResponse<any>
    if (menu.id) {
      response = await api.put(`${ROUTE}/${menu.id}`, menu)
    } else {
      response = await api.post(ROUTE, menu)
    }

    return response.data.data
  }
)

export const menuSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    pushMenu: (state, action: PayloadAction<Menu>) => {
      const menuIdx = state.menus.findIndex(menu => menu.id === action.payload.id)
      if (menuIdx !== -1) {
        state.menus[menuIdx] = action.payload
      } else {
        state.menus.push(action.payload)
      }
    },
    removeMenu: (state, action: PayloadAction<Menu>) => {
      const menuIdx = state.menus.findIndex(menu => menu.id === action.payload.id)
      if (menuIdx >= 0) {
        state.menus.splice(menuIdx, 1)
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenus.fulfilled, (state, action) => {
      state.menus = action.payload
    })
    builder.addCase(saveMenu.fulfilled, (state, action) => {
      const menuIdx = state.menus.findIndex(menu => menu.id === action.payload.id)
      if (menuIdx !== -1) {
        state.menus[menuIdx] = action.payload
      } else {
        state.menus.push(action.payload)
      }
    })
  },
})

export const menuSelector = createSelector<RootState, string | undefined, Menu[], string | undefined, Menu | undefined>(
  state => state.menu.menus,
  (_, id) => id,
  (menus, id) => id ? menus.find(menu => menu.id === id) : undefined
)

export const { pushMenu, removeMenu } = menuSlice.actions

export default menuSlice.reducer