import { Parent } from "./parent"

export type Student = {
  id?: string
  schoolId: string
  forename: string
  surname: string
  year: string
  form: string
  admission_status: boolean
  fsm: boolean
  uifsm: boolean
  premium: boolean
  meal_type?: string
  allergy: string[]
  dietary: string
  parent_title?: string
  parent_forename?: string
  parent_surname?: string
  parent_mobile?: string
  parent_email?: string
  balance?: number
  parent?: Parent
}

export const blankStudent: Student = {
  schoolId: '',
  forename: '',
  surname: '',
  year: '',
  form: '',
  admission_status: false,
  fsm: false,
  uifsm: false,
  premium: false,
  allergy: [],
  dietary: '',
  parent_title: '',
  parent_forename: '',
  parent_surname: '',
  parent_mobile: '',
  parent_email: '',
}