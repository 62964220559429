import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Input from "./form/Input";
import { StudentInformationProps } from "./StudentInformation";

export default function ParentInformation({ student, errors, saving, onFieldChange, onSave }: StudentInformationProps) {
  const history = useHistory()
  const [balance, setBalance] = useState('0.00')

  useEffect(() => {
    if (student.balance) {
      setBalance((student.balance / 100).toFixed(2))
    }
  }, [student])

  const updateBalance = (event: React.SyntheticEvent) => {
    // event.target.value
    setBalance(balance => {
      const bal = parseFloat(balance)
      onFieldChange('balance', bal * 100)
      return bal.toFixed(2)
    })
  }

  const getError = (field: string) => {
    if (errors[field]) return errors[field][0]
  }

  return (
    <form className="text-left space-y-8 divide-y divide-gray-200">
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="pt-8">
          <div>
            <h2 className="text-xl font-bold font-cursive text-brand-green-dark">Parent Information</h2>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-1">
              <Input
                label="Title"
                error={getError('parent_title')}
                type="text"
                name="title"
                id="title"
                value={student.parent_title}
                onChange={event => onFieldChange('parent_title', event.target.value)}
              />
            </div>

            <div className="sm:col-span-2">
              <Input
                label="Forename"
                error={getError('parent_forename')}
                type="text"
                name="forename"
                id="forename"
                value={student.parent_forename}
                onChange={event => onFieldChange('parent_forename', event.target.value)}
              />
            </div>

            <div className="sm:col-span-2">
              <Input
                label="Surname"
                error={getError('parent_surname')}
                type="text"
                name="surname"
                id="surname"
                value={student.parent_surname}
                onChange={event => onFieldChange('parent_surname', event.target.value)}
              />
            </div>

            <div className="sm:col-span-2">
              <Input
                label="Mobile"
                error={getError('parent_mobile')}
                type="tel"
                name="mobile"
                id="mobile"
                value={student.parent_mobile}
                onChange={event => onFieldChange('parent_mobile', event.target.value)}
              />
            </div>

            <div className="sm:col-span-3">
              <Input
                label="Email"
                error={getError('parent_email')}
                type="email"
                name="email"
                id="email"
                value={student.parent_email}
                onChange={event => onFieldChange('parent_email', event.target.value)}
              />
            </div>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <Input
                label="Balance (£)"
                hint="Accrued via substitutions and cancellations"
                error={getError('balance')}
                type="text"
                name="balance"
                id="balance"
                value={balance}
                onChange={event => setBalance(event.target.value)}
                onBlur={event => updateBalance(event)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            className="brand-button-tertiary"
            // className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => history.goBack()}
            disabled={saving}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 brand-button"
            onClick={onSave}
            disabled={saving}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  )
}