import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { TitleContext } from "../context/TitleContext"
import { useAppDispatch, useAppSelector } from "../hooks/useRedux"
import { fetchStaff } from "../store/staffSlice"
import { classNames } from "../utils"
import Pagination from "./Pagination"

const PER_PAGE = 10

export default function Staff() {
  const titleContext = useContext(TitleContext)
  const dispatch = useAppDispatch()
  const staff = useAppSelector(state => state.staff.staff)
  const [page, setPage] = useState(1)
  const [numPages, setNumPages] = useState(1)

  useEffect(() => {
    titleContext.setTitle('Staff')
  }, [titleContext])

  useEffect(() => {
    dispatch(fetchStaff())
  }, [dispatch])

  useEffect(() => {
    setNumPages(Math.ceil(staff.length / PER_PAGE))
  }, [staff])

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="mb-4 flex justify-end items-center">
            <Link
              to={{
                pathname: `/staff/create`,
                state: { title: 'Create Staff' },
              }}
              className="print:hidden brand-button flex items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
              </svg>
              Create
            </Link>
          </div>
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  {/* <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Phone
                  </th> */}
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {staff.length > 0 ? staff.slice((page - 1) * PER_PAGE, Math.min(staff.length, page * PER_PAGE)).map((staff, staffIdx) => (
                  <tr key={staff.email} className={staffIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{staff.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <p className={classNames(
                        "mr-2",
                        staff.type === "admin" ? "brand-badge" : "brand-badge-secondary"
                      )}>
                        {staff.type}
                      </p>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{staff.displayName}</td>
                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{staff.phoneNumber || '-'}</td> */}
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end">
                        <Link
                          to={{
                            pathname: `staff/${staff.email}`,
                            state: { title: staff.email },
                          }}
                          className="brand-link"
                        >
                          Edit
                        </Link>
                      </div>
                    </td>
                  </tr>
                )) : (
                  <tr className="bg-white">
                    <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      No staff have been added, create a staff member to get started.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {numPages > 1 && (
            <div className="mt-4">
              <Pagination page={page} numPages={numPages} setPage={setPage} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
