export type MenuMeal = {
  id: string
  name: string
  price?: string | undefined
  days_available?: Array<string | null>
  year_groups?: Array<string | number | null>
  staff: boolean
}

export type Menu = {
  id?: string
  name: string
  meals?: MenuMeal[]
}

export const blankMenu = {
  name: '',
}

export const blankMenuMeal = {
  id: '',
  name: '',
  staff: false,
}