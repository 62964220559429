import { LockClosedIcon } from '@heroicons/react/solid'
import { useContext, useState } from 'react'
// import { useHistory } from 'react-router-dom'
import { auth } from '../../auth'
import logo from '../../assets/lf-logo.png'
import { useHistory } from 'react-router-dom'
import { NotificationContext } from '../../context/NotificationContext'

export default function Maintenance() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-56 w-auto absolute top-0 left-0 right-0"
            src={logo}
            alt="Love Food"
          />
          <h2 className="mt-12 text-center text-3xl font-extrabold text-gray-900">The system is currently closed for maintenance</h2>
        </div>
      </div>
    </div>
  )
}
