/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { classNames } from '../../utils'

export type SelectOption = {
  id: string | undefined
  name: string
  disabled?: boolean
}

type SelectProps = {
  label?: string
  selectedId?: string,
  options: SelectOption[]
  onChange: (selected: SelectOption | undefined) => void
  error?: string
}

export default function Select({ label, selectedId, options, onChange, error }: SelectProps) {
  const [selected, setSelected] = useState(options.find(option => option.id === selectedId))

  useEffect(() => {
    setSelected(options.find(option => option.id === selectedId))
  }, [selectedId, options])

  return (
    <>
      <Listbox value={selected} onChange={onChange}>
        {({ open }) => (
          <>
            <Listbox.Label
              className={classNames(
                error ? 'text-red-600' : 'text-gray-600 focus-within:text-brand-green-dark',
                "block text-sm font-medium"
              )}
            >
              {label}
            </Listbox.Label>
            <div className="relative">
              <Listbox.Button
                className={classNames(
                  error ? 'focus:ring-red-600 focus:border-red-600 border-red-400 hover:border-red-600' : 'focus:ring-brand-green-dark focus:border-brand-green-dark border-gray-300 hover:border-gray-400',
                  "bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-brand-green focus:border-brand-green sm:text-sm"
                )}
              >
                <span className="block truncate">{selected?.name || 'Please Select'}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {options.map((option, optionIdx) => (
                    <Listbox.Option
                      key={optionIdx}
                      disabled={option.disabled}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-brand-green bg-opacity-50' : '',
                          'text-left cursor-default select-none relative py-2 pl-3 pr-9 text-gray-700'
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className='block truncate'>
                            {option.name}
                          </span>

                          {selected ? (
                            <span
                              className={'absolute inset-y-0 right-0 flex items-center pr-4'}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </>
  )
}
