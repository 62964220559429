import { PlusIcon } from "@heroicons/react/outline"
import { format, fromUnixTime } from "date-fns"
import { useContext, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { TitleContext } from "../context/TitleContext"
import { useAppDispatch, useAppSelector } from "../hooks/useRedux"
import { schoolSelector } from "../store/schoolSlice"
import { fetchTerms, schoolTermSelector } from "../store/termSlice"
import BackButton from "./BackButton"

export default function Terms() {
  const titleContext = useContext(TitleContext)
  const schools = useAppSelector(state => state.auth.schools)
  const search = useLocation().search
  const dispatch = useAppDispatch()
  const [school, setSchool] = useState<string>()
  const schoolModel = useAppSelector(state => schoolSelector(state, school))
  const terms = useAppSelector(state => school ? schoolTermSelector(state, school) : [])

  useEffect(() => {
    if (school) dispatch(fetchTerms(school))
  }, [dispatch, school])

  useEffect(() => {
    titleContext.setTitle(schoolModel ? `${schoolModel.name} - Terms` : 'Terms')
  }, [titleContext, schoolModel])

  useEffect(() => {
    if (schools.length === 0) {
      const query = new URLSearchParams(search)
      setSchool(query.get('school') ?? undefined)
    } else {
      setSchool(schools[0])
    }
  }, [schools, search, dispatch])

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="mb-4 flex justify-end items-center">
            <BackButton className="print:hidden mr-auto" />
            {/* <button className="print:hidden brand-button flex items-center mr-4" onClick={() => { window.print() }}>
              <PrinterIcon className="w-4 h-4 mr-2" />
              Print
            </button> */}
            <Link
              to={{
                pathname: `/terms/create`,
                search: `?school=${school}`,
                state: { title: 'Create Term' },
              }}
              className="print:hidden brand-button flex items-center"
            >
              <PlusIcon className="w-4 h-4 mr-2" />
              Create
            </Link>
          </div>
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Start
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    End
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {terms.length > 0 ? terms.map((term, termIdx) => (
                  <tr key={term.id} className={termIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{term.id}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {term.start ? format(fromUnixTime(term.start), 'dd/MM/yyyy') : '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {term.end ? format(fromUnixTime(term.end), 'dd/MM/yyyy') : '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end">
                        <Link
                          to={{
                            pathname: `/terms/${term.id}`,
                            search: `?school=${school}`,
                            state: { title: 'Edit Term' },
                          }}
                          className="brand-link"
                        >
                          Edit
                        </Link>
                      </div>
                    </td>
                  </tr>
                )) : (
                  <tr className="bg-white">
                    <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      No terms have been added, create a term to get started.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {
            terms.length === 0 &&
            <p className="mt-2 text-left text-xs text-gray-400">
              Ensure you have created your menus before attempting to create a term.
            </p>
          }
        </div>
      </div>
    </div>
  )
}