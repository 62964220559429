export type Staff = {
  displayName: string
  email: string
  password?: string
  password_confirmation?: string
  type: string
  phoneNumber?: string
  schools?: string[]
}

export const blankStaff: Staff = {
  displayName: '',
  email: '',
  password: '',
  password_confirmation: '',
  type: 'staff',
  phoneNumber: '',
  schools: ['']
}
