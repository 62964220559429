import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { differenceInHours } from "date-fns/esm"
import { RootState, ThunkAPI } from "."
import api from "../api"
import { Parent } from "../types/parent"
import { Student } from "../types/student"
import { resetAuthState } from "./authSlice"

export const ROUTE = '/students'

type State = {
  allStudents: Student[]
  students: { [school: string]: Student[] }
  parents: Parent[]
  parentSchool?: string
  studentsFetched: { [school: string]: number }
  parentsFetched?: number
  studentsLoading: { [school: string]: boolean },
}

const initialState: State = {
  allStudents: [],
  students: {},
  parents: [],
  studentsFetched: {},
  studentsLoading: {},
}

export const fetchStudents = createAsyncThunk<Student[], string | undefined, ThunkAPI>(
  'students/fetch',
  async (school, thunkAPI) => {
    const response = await api.get(ROUTE, { params: { school } })
    return response.data.data
  },
  {
    condition: (schoolId, thunkAPI) => {
      const { student } = thunkAPI.getState()

      if (!schoolId) {
        return true
      }

      if (student.studentsLoading[schoolId] === true) {
        return false
      }

      const schoolStudents = student.students[schoolId]

      if (!schoolStudents || schoolStudents.length === 0) {
        return true
      }

      const time = student.studentsFetched[schoolId]

      return differenceInHours(Date.now(), time) >= 2
    }
  }
)

export const fetchParents = createAsyncThunk<Parent[], string | undefined, ThunkAPI>(
  'parents/fetch',
  async (school, thunkAPI) => {
    const response = await api.get('/parent', { params: { school } })
    return response.data.data
  },
  {
    condition: (school, thunkAPI) => {
      const { student } = thunkAPI.getState()

      if (!school || !student.parents || student.parents.length === 0) {
        return true
      }

      if (school !== student.parentSchool) {
        return true
      }

      return !student.parentsFetched || (differenceInHours(Date.now(), student.parentsFetched) >= 2)
    }
  }
)

export const studentSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    pushStudent: (state, action: PayloadAction<Student>) => {
      const studentIdx = state.students[action.payload.schoolId].findIndex(student => student.id === action.payload.id)
      const student = {
        ...action.payload,
        parent_title: action.payload.parent?.title,
        parent_forename: action.payload.parent?.forename,
        parent_surname: action.payload.parent?.surname,
        parent_mobile: action.payload.parent?.mobile,
        parent_email: action.payload.parent?.email,
        balance: action.payload.parent?.balance || 0,
      }

      if (studentIdx !== -1) {
        state.students[action.payload.schoolId][studentIdx] = student
      } else {
        state.students[action.payload.schoolId].push(student)
      }
    },
    removeStudent: (state, action: PayloadAction<Student>) => {
      const studentIdx = state.students[action.payload.schoolId].findIndex(student => student.id === action.payload.id)
      if (studentIdx >= 0) {
        state.students[action.payload.schoolId].splice(studentIdx, 1)
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetAuthState.type, (state, action) => {
      return initialState
    })
    builder.addCase(fetchStudents.pending, (state, action) => {
      if (action.meta.arg) {
        state.studentsLoading[action.meta.arg] = true
      }
    })
    builder.addCase(fetchStudents.fulfilled, (state, action) => {
      if (action.meta.arg) {
        state.studentsFetched[action.meta.arg] = Date.now()
      }

      const newStudents = action.payload.map(student => ({
        ...student,
        parent_title: student.parent?.title,
        parent_forename: student.parent?.forename,
        parent_surname: student.parent?.surname,
        parent_mobile: student.parent?.mobile,
        parent_email: student.parent?.email,
        balance: student.parent?.balance || 0,
      }))

      newStudents.forEach(student => {
        const studentIdx = (state.students[student.schoolId] || []).findIndex(s => s.id === student.id)
        if (studentIdx !== -1) {
          state.students[student.schoolId][studentIdx] = student
        } else if (state.students[student.schoolId]) {
          state.students[student.schoolId].push(student)
        } else {
          state.students[student.schoolId] = [student]
        }
      })
    })
    builder.addCase(fetchParents.fulfilled, (state, action) => {
      state.parentsFetched = Date.now()
      state.parents = action.payload
    })
  },
})

export const studentSelector = createSelector<RootState, string | undefined, Student[], string | undefined, Student | undefined>(
  state => Object.values(state.student.students).flat(),
  (_, id) => id,
  (students, id) => id ? students.find(student => student.id === id) : undefined
)

export const schoolStudentSelector = createSelector<RootState, string | undefined, Student[], string | undefined, Student[]>(
  state => Object.values(state.student.students).flat(),
  (_, id) => id,
  (students, id) => id ? students.filter(student => student.schoolId === id) : []
)

export const schoolAllergyStudentSelector = createSelector<RootState, { school: string | undefined, allergy: boolean }, Student[], { school: string | undefined, allergy: boolean }, Student[]>(
  state => Object.values(state.student.students).flat(),
  (_, id) => id,
  (students, id) => id
    ? students.filter(student => {
      if (id.allergy) {
        return student.schoolId === id.school
          && (student.allergy?.length > 0 || student.dietary?.length > 0)
      }
      return student.schoolId === id.school
    })
    : []
)

export const { pushStudent, removeStudent } = studentSlice.actions

export default studentSlice.reducer