import { DetailedHTMLProps, InputHTMLAttributes, useState } from "react"
import { SwatchesPicker, SwatchesPickerProps } from 'react-color';

export type ColourPickerProps = {
  label: string
  error?: string | undefined
  name: string
} & SwatchesPickerProps


export default function ColourPicker({ label, error, ...props }: ColourPickerProps) {
  const [displayColourPicker, setDisplayColourPicker] = useState(false);
  const [displayColour, setDisplayColour] = useState((props.color as string | undefined));

  const handleClick = () => {
    setDisplayColourPicker(!displayColourPicker)
  }

  const handleClose = () => {
    setDisplayColourPicker(false)
  }

  console.log('displayColour', displayColour)
  console.log('props.color', props.color)
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <button
          type="button"
          className="brand-button-tertiary"
          onClick={() => handleClick()}
        >
          Pick Color</button>
        {displayColourPicker ? <SwatchesPicker
          {...props}
          color={(props.color ?? undefined)}
          onChangeComplete={(colorResult, event) => {
            // if (!colorResult || !colorResult.hex) return;
            props.onChangeComplete && props.onChangeComplete(colorResult, event)
            setDisplayColour(colorResult.hex)
            handleClose()
          }}
        /> : null}
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={props.name} className="font-medium text-gray-600 capitalize py-2 px-4 border hover:border-transparent rounded" style={{
          backgroundColor: (props.color as string | undefined)
        }}>
          {label}
        </label>
      </div>
    </div>
  )
}
