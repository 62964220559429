import React from "react";

export type TitleContextProps = {
  title: string
  setTitle: (title: string) => void
}

export const TitleContext = React.createContext<TitleContextProps>({
  title: 'Love Food',
  setTitle: () => {},
});