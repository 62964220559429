import { BadgeCheckIcon, LockClosedIcon } from "@heroicons/react/solid";
import { addDays, format, isPast, isToday, parse } from "date-fns";
import { truncate } from "lodash";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import api from "../api";
import { NotificationContext } from "../context/NotificationContext";
import { useAppDispatch } from "../hooks/useRedux";
import { fetchStudents } from "../store/studentSlice";
import { Booking } from "../types/booking";
import { Student } from "../types/student";
import { classNames } from "../utils";

export type StudentRowProps = {
  student: Student
  studentIdx: number
  week: Date
  bookings: Booking[]
  onBook: (date: Date) => void
  onBookingCancelled: () => void
}

const dateStringFormat = 'yyyy-MM-dd'

export default function StudentRow({ student, studentIdx, week, bookings, onBook, onBookingCancelled }: StudentRowProps) {
  const notificationContext = useContext(NotificationContext)
  const dispatch = useAppDispatch()
  const [hasAllergies, setHasAllergies] = useState(false)
  // const [showAllergies, setShowAllergies] = useState(false)
  // const bookings = useAppSelector(state => state.booking.bookings)

  useEffect(() => {
    setHasAllergies((student.allergy && student.allergy?.length > 0) || (student.dietary && student.dietary?.length > 0) || false)
  }, [student])

  const bookingForDate = (date: Date) => bookings.find(b => b.studentId === student.id && b.bookingDate === format(date, dateStringFormat))

  const canCancelBooking = (booking: Booking) => {
    const date = parse(booking.bookingDate, dateStringFormat, new Date())

    return isToday(date) || !isPast(date)
  }

  const bookingCol = (date: Date) => {
    const booking = bookingForDate(date)

    if (booking) {
      // return (
      //   <>
      //     <button
      //       className="brand-badge"
      //       onClick={() => setOpen(true)}
      //     >
      //       {booking.meal.name}
      //     </button>
      //     <BookingModal booking={booking} open={open} setOpen={setOpen} />
      //   </>
      // )

      return (
        <button
          className="brand-button inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs"
          disabled={!canCancelBooking(booking)}
          onClick={async () => {
            // eslint-disable-next-line no-restricted-globals
            if (confirm(`Are you sure you want to cancel this booking?`)) {
              try {
                await api.post('/refund', { studentId: booking.studentId, bookings: [booking.id] })
                notificationContext.showNotification('success', 'Booking Cancelled.')
                onBookingCancelled()
              } catch (error) {
                notificationContext.showNotification('error', 'Unable to cancel Booking.')
              }
            }
          }}
        >
          <span data-tip data-for={`booking-${booking.id}`}>{truncate(booking.meal?.name || 'Meal Unavailable')}</span>
          <ReactTooltip id={`booking-${booking.id}`} place="bottom" effect="solid">{booking.meal?.name || 'Meal Unavailable'}</ReactTooltip>
        </button>
      )
    }

    return (
      <button className="brand-badge-secondary print:hidden" onClick={() => onBook(date)}>
        Book Now
      </button>
    )
  }

  const mealTypeColor = (type?: string) => {
    let background = 'bg-brand-green'
    if (student.uifsm) {
      background = 'bg-rose-400'
    } else if (student.fsm) {
      background = 'bg-pink-400'
    } else if (student.premium) {
      background = 'bg-orange-400'
    }

    return background
  }

  const onInvite = async () => {
    if (! student.parent) return

    try {
      await api.post('/invite', { parents: [ student.parent.id ] })
      notificationContext.showNotification('success', 'Invitation sucessfully sent!')
      dispatch(fetchStudents(student.schoolId))
    } catch (error) {
      notificationContext.showNotification('error', 'Failed to invite parent, try again later.')
    }
  }

  return (
    <>
      <tr
        key={student.id}
        className={studentIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
        // onMouseEnter={() => setShowAllergies(true)}
        // onMouseLeave={() => setShowAllergies(false)}
      >
        <td className={classNames('sticky-left px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-l-8', hasAllergies ? 'border-red-500' : 'border-transparent')}>
          <div className="flex items-center justify-between">
            <div>
              {student.forename} {student.surname}
              <span className="block text-xs font-normal text-gray-400">{student.id}</span>
              {/* {
                hasAllergies &&
                <Transition
                  show={showAllergies}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Popover>
                    <Popover.Panel static className="absolute min-w-xs -ml-4 -mt-2 px-4 py-2 rounded-lg shadow whitespace-nowrap bg-red-50 text-sm text-gray-600 border border-red-500">
                      {[...student.allergy || [], student.dietary].join(', ')}
                    </Popover.Panel>
                  </Popover>
                </Transition>
              } */}
            </div>
            {
              student.parent?.registered_at && (
                <div className="ml-6">
                  <BadgeCheckIcon className="w-5 h-5 text-brand-green-dark" data-tip data-for="registered" />
                  <ReactTooltip id="registered" place="right" effect="solid">Parent successfully registered</ReactTooltip>
                </div>
              )
            }
            {
              student.parent?.registration_locked && (
                <div className="ml-6">
                  <LockClosedIcon className="w-5 h-5 text-brand-red" data-tip data-for="registered" />
                  <ReactTooltip id="registered" place="right" effect="solid">Registration has been locked, re-invite this parent to unlock.</ReactTooltip>
                </div>
              )
            }
          </div>
        </td>
        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">{student.year}</td>
        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">{student.form}</td>
        <td className="px-4 py-4 whitespace-normal text-sm text-gray-500 min-w-xs break-normal">
          {[...student.allergy || [], student.dietary].join(', ')}
        </td>
        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
          {
            (student.meal_type === 'paid' || student.meal_type === 'teachers') ? 'Paid' : (
              <>
              <p className={classNames(
                "print:hidden inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs font-medium text-white uppercase",
                mealTypeColor(student.meal_type)
              )}>
                {student.meal_type}
              </p>
              <span className="hidden print:block">{student.meal_type}</span>
              </>
            )
          }
        </td>
        {/* Bookings */}
        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
          {bookingCol(week)}
        </td>
        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
          {bookingCol(addDays(week, 1))}
        </td>
        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
          {bookingCol(addDays(week, 2))}
        </td>
        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
          {bookingCol(addDays(week, 3))}
        </td>
        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
          {bookingCol(addDays(week, 4))}
        </td>
        {/* End Bookings */}
        <td className={classNames('print:hidden px-6 py-4 whitespace-nowrap text-right text-sm font-medium border-r-8', hasAllergies ? 'border-red-500' : 'border-transparent')}>
          <div className="flex justify-between">
            <button
              onClick={onInvite}
              className="mr-2 text-brand-green underline hover:opacity-75"
            >
              Invite
            </button>
            <Link
              to={{
                pathname: `/students/${student.id}`,
                search: `?school=${student.schoolId}`,
                state: { title: `${student.forename} ${student.surname}` },
              }}
              className="text-brand-green underline hover:opacity-75"
            >
              Edit
            </Link>
          </div>
        </td>
      </tr>
    </>
  )
}