import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useSchoolQuery() {
  const search = useLocation().search
  const [schoolId, setSchoolId] = useState<string>()

  useEffect(() => {
    const query = new URLSearchParams(search)
    setSchoolId(query.get('school') ?? undefined)
  }, [search])

  return schoolId
}
