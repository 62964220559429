import { PlusIcon, XIcon } from "@heroicons/react/outline";
import axios, { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import api from "../api";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { menuSelector, pushMenu, removeMenu, ROUTE } from "../store/menuSlice";
import { NotificationContext } from "../context/NotificationContext";
import { Errors } from "../types/errors";
import { blankMenu, Menu as MenuModel, MenuMeal } from "../types/menu";
import { classNames } from "../utils";
import BackButton from "./BackButton";
import DeleteModal from "./DeleteModal";
import Checkbox from "./form/Checkbox";
import Input from "./form/Input";
import Select from "./form/Select";
import { TitleContext } from "../context/TitleContext";
import { v4 as uuid } from 'uuid'

type Meal = { mealId: string } & MenuMeal

const days = ['mon', 'tue', 'wed', 'thu', 'fri'];
const groups = ['teachers', 'nursery', 'reception', '1', '2', '3', '4', '5', '6'];

export default function Menu(props: RouteComponentProps<{ id?: string }>) {
  const id = props.match.params.id
  const titleContext = useContext(TitleContext)
  const notificationContext = useContext(NotificationContext)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const actualMenu = useAppSelector(state => menuSelector(state, id))
  const availableMeals = useAppSelector(state => state.meal.meals)
  const [menu, setMenu] = useState<MenuModel>({ ...blankMenu })
  const [meals, setMeals] = useState<Meal[]>([])
  const [errors, setErrors] = useState<Errors>({})
  const [saving, setSaving] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  useEffect(() => {
    titleContext.setTitle(actualMenu ? actualMenu.name : 'Create Menu')
  }, [titleContext, actualMenu])

  useEffect(() => {
    if (actualMenu) {
      setMenu(actualMenu)
      setMeals(
        actualMenu.meals
          ? actualMenu.meals.map(meal => ({
            ...meal,
            price: meal.price ? (parseFloat(meal.price) / 100).toFixed(2) : undefined,
            mealId: uuid()
          }))
          : []
      )
    }
  }, [actualMenu])

  const onFieldChange = (field: string, value: any) => {
    setMenu(menu => ({ ...menu, [field]: value }))
  }

  const onMealChanage = (meal: MenuMeal, idx: number, id: string) => {
    const foundMeal = availableMeals.find(meal => meal.id === id)

    if (!foundMeal || !foundMeal.id) {
      return
    }

    const newVal = [...meals]
    newVal[idx] = {
      ...newVal[idx],
      id: foundMeal.id,
      name: foundMeal.name,
      price: meal.price || undefined,
    }

    setMeals(newVal)
  }

  const onMealFieldChange = (idx: number, field: string, value: any) => {
    const newVal = [...meals]
    newVal[idx] = { ...newVal[idx], [field]: value }
    setMeals(newVal)
  }

  // const mealUpdated = (idx: number, meal: MenuMeal) => {
  //   const newVal = [...meals]
  //   newVal[idx] = meal
  //   setMeals(newVal)
  // }

  const onAddMeal = () => {
    setMeals(meals => [...meals, {
      mealId: uuid(),
      id: availableMeals[0]?.id || '',
      name: availableMeals[0]?.name || 'New Meal',
      staff: false,
      days_available: days,
      year_groups: groups,
    }])
    window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' })
  }

  const onDelete = async () => {
    if (!actualMenu) return

    try {
      await api.delete(`/menus/${actualMenu.id}`)
      dispatch(removeMenu(actualMenu))
      history.goBack()
      notificationContext.showNotification('success', 'Menu successfully deleted!')
    } catch (error) {
      notificationContext.showNotification('error', 'Failed to deleted menu.')
    }
  }

  const onSave = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSaving(true)
    setErrors({})

    const payload = {
      ...menu,
      meals: meals?.map(meal => ({
        ...meal,
        price: meal.price ? Math.round(parseFloat(meal.price) * 100) : undefined
      }))
    }

    let response: AxiosResponse<any>
    try {
      if (actualMenu?.id) {
        response = await api.put(`${ROUTE}/${actualMenu.id}`, payload)
      } else {
        response = await api.post(ROUTE, payload)
      }

      const data = response.data.data
      dispatch(pushMenu(data))
      notificationContext.showNotification('success', 'Menu successfully saved!')
      history.replace(`/menus/${data.id}`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          setErrors(error.response?.data.errors)
        }
      }

      notificationContext.showNotification('error', 'Failed to save menu.')
    }

    setSaving(false)
  }

  const getError = (field: string) => {
    if (errors[field]) return errors[field][0]
  }

  return (
    <div className="max-w-4xl flex flex-col mx-auto">
      <BackButton className="mr-auto" />
      <form className="text-left space-y-8 divide-y divide-gray-200" onSubmit={onSave}>
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            <div>
              <h2 className="text-xl font-bold font-cursive text-brand-green-dark">Menu Information</h2>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <Input
                  label="Name"
                  error={getError('name')}
                  type="text"
                  name="name"
                  id="name"
                  value={menu.name}
                  onChange={event => onFieldChange('name', event.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="pt-8">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold font-cursive text-brand-green-dark">Meals</h2>
              <button type="button" className="brand-button-secondary flex items-center" onClick={onAddMeal}>
                <PlusIcon className="w-4 h-4 mr-2" />
                Add
              </button>
            </div>
            <div className="space-y-6 divide-y divide-gray-200">
              {
                (meals.length > 0)
                  ? meals.map((meal, mealIdx) => {
                    return (
                      <div key={meal.mealId} className="pt-6">
                        <div className="flex items-center">
                          <h3 className="text-base font-medium font-cursive tracking-wide text-brand-green-dark">{meal.name}</h3>
                          <button
                            type="button"
                            className="ml-4 rounded-xl p-2 text-sm font-medium bg-red-100 hover:bg-red-50 text-red-600"
                            onClick={() => setMeals(meals => {
                              const arr = [...meals]
                              arr.splice(mealIdx, 1)
                              return arr
                            })}
                          >
                            <XIcon className="w-3.5 h-3.5" />
                          </button>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-3">
                            <Select
                              label="Meal"
                              options={availableMeals.map(meal => ({ id: meal.id!, name: meal.name })).sort((a, b) => a.name.localeCompare(b.name))}
                              selectedId={meal.id}
                              onChange={selected => onMealChanage(meal, mealIdx, selected?.id!)}
                              error={getError(`meals.${mealIdx}.id`)}
                            />
                          </div>
                        </div>

                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-2">
                            <Input
                              label="Menu Price"
                              hint="This price overrides the meal's base price"
                              error={getError('price')}
                              type="text"
                              name="price"
                              id="price"
                              placeholder="0.00"
                              value={meal.price}
                              onChange={event => onMealFieldChange(mealIdx, 'price', event.target.value)}
                              onBlur={event => onMealFieldChange(mealIdx, 'price', event.target.value ? parseFloat(event.target.value).toFixed(2) : undefined)}
                            />
                          </div>
                        </div>

                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-6">
                            <p
                              className={classNames(
                                getError('days_available') ? 'text-red-600' : 'text-gray-600 focus-within:text-brand-green-dark',
                                'block text-sm font-medium'
                              )}
                            >
                              Days Available
                            </p>
                          </div>
                          {days.map((day) => (
                            <div key={day} className="sm:col-span-1">
                              <Checkbox
                                label={day}
                                name={`${mealIdx}-${day}`}
                                id={`${mealIdx}-${day}`}
                                checked={meal.days_available?.includes(day)}
                                onChange={event => {
                                  const checked = event.target.checked
                                  let days = [...(meal.days_available || [])]
                                  if (checked) {
                                    days.push(day)
                                  } else {
                                    days.splice(days.indexOf(day), 1)
                                  }
                                  onMealFieldChange(mealIdx, 'days_available', days)
                                }}
                              />
                            </div>
                          ))}
                        </div>

                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8">
                          <div className="sm:col-span-8">
                            <p
                              className={classNames(
                                getError('days_available') ? 'text-red-600' : 'text-gray-600 focus-within:text-brand-green-dark',
                                'block text-sm font-medium'
                              )}
                            >
                              Available Year Groups
                            </p>
                          </div>
                          {groups.map((group) => (
                            <div key={group} className="sm:col-span-1">
                              <Checkbox
                                label={group.toString()}
                                name={`${mealIdx}-${group}`}
                                id={`${mealIdx}-${group}`}
                                checked={meal.year_groups?.includes(group)}
                                onChange={event => {
                                  const checked = event.target.checked
                                  let groups = [...(meal.year_groups || [])]
                                  if (checked) {
                                    groups.push(group)
                                  } else {
                                    groups.splice(groups.indexOf(group), 1)
                                  }
                                  onMealFieldChange(mealIdx, 'year_groups', groups)
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  })
                  : (
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        <h3 className="text-base font-medium font-cursive tracking-wide">No Meals Assigned</h3>
                      </div>
                    </div>
                  )
              }
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="brand-button-tertiary"
              onClick={() => history.replace('/menus')}
              disabled={saving}
            >
              Cancel
            </button>
            {
              actualMenu &&
              <button
                type="button"
                className="ml-3 brand-button-secondary bg-red-100 hover:bg-red-50 text-red-600"
                onClick={() => setDeleteOpen(true)}
                disabled={saving}
              >
                Delete
              </button>
            }
            <button
              type="submit"
              className="ml-3 brand-button"
              disabled={saving}
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <DeleteModal open={deleteOpen} setOpen={setDeleteOpen} onDelete={onDelete} />
    </div>
  )
}
