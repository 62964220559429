export type Meal = {
  id?: string
  name: string
  price?: string
  item_code: string
  additional_info?: string
  dietary_info?: string
  // allergy_info?: string[]
  dinner_type?: string
  colour?: string
  icon?: string
  booking_count?: string
}

export const blankMeal = {
  name: '',
  price: '',
  item_code: '',
  additional_info: '',
  dietary_info: '',
  // allergy_info: [],
  dinner_type: '',
  colour: '',
  icon: '',
}