import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, ThunkAPI } from ".";
import api from "../api";
import { Import } from "../types/import";

export const ROUTE = '/import-status';

type State = {
  imports: Import[]
}

const initialState: State = {
  imports: []
}

export const fetchImports = createAsyncThunk<Import[], void, ThunkAPI>(
  'imports/fetch',
  async (_, thunkAPI) => {
    const response = await api.get(ROUTE)
    return response.data.data
  }
)

export const importSlice = createSlice({
  name: 'imports',
  initialState,
  reducers: {
    pushImport: (state, action: PayloadAction<Import>) => {
      const importIdx = state.imports.findIndex(i => i.id === action.payload.id)
      if (importIdx !== -1) {
        state.imports[importIdx] = action.payload
      } else {
        state.imports.push(action.payload)
      }
    },
    removeImport: (state, action: PayloadAction<Import>) => {
      const importIdx = state.imports.findIndex(i => i.id === action.payload.id)
      if (importIdx >= 0) {
        state.imports.splice(importIdx, 1)
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchImports.fulfilled, (state, action) => {
      state.imports = action.payload
    })
  },
})

export const importSelector = createSelector<RootState, string | undefined, Import[], string | undefined, Import | undefined>(
  state => state.import.imports,
  (_, id) => id,
  (imports, id) => id ? imports.find(i => i.id === id) : undefined
)

export const { pushImport, removeImport } = importSlice.actions

export default importSlice.reducer