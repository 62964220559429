import { useEffect, useState } from "react"
import api from "../api"
import { useAppSelector } from "../hooks/useRedux"
import { Meal } from "../types/meal"
import DatePicker from "./form/DatePicker"
import { format, fromUnixTime, getUnixTime } from "date-fns"
import Select from "./form/Select"

type Report = {
  hot?: Meal[]
  cold?: Meal[]
  allergy?: Meal[]
}

export default function CateringReport() {
  const schools = useAppSelector(state => state.school.schools)
  const [school, setSchool] = useState<string>()
  const [date, setDate] = useState<Date>(new Date())
  const [report, setReport] = useState<Report>({ hot: [], cold: [], allergy: [] })

  useEffect(() => {
    setSchool(schools.length > 0 ? schools[0].id : undefined)
  }, [schools])

  useEffect(() => {
    if (!school) return

    api
      .get('/catering-report', { params: { school, date: format(date, 'yyyy-MM-dd') } })
      .then(response => {
        setReport(response.data)
      })
  }, [school, date])

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block print:block min-w-full sm:px-6 lg:px-8">
          <div className="mb-4 flex justify-end items-center">
            <div className="mr-4 w-60">
              <Select
                label=""
                options={schools.map(school => ({ id: school.id!, name: school.name }))}
                selectedId={school}
                onChange={selected => setSchool(selected?.id)}
              />
            </div>
            <div className="mr-6">
              <DatePicker date={getUnixTime(date)} onChange={date => date && setDate(fromUnixTime(date))} />
            </div>
            <button className="print:hidden brand-button flex items-center" onClick={() => { window.print() }}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
              </svg>
              Print
            </button>
          </div>
          {/* Hot Meals */}
          <h2 className="text-xl font-bold font-cursive text-brand-green-dark text-left">
            Hot Meals
          </h2>
          <div className="mt-2 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg print:rounded-none print:shadow-none print:border-none">
            <table className="min-w-full divide-y divide-gray-200 text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Code
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Booked
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  report.hot && report.hot.length > 0 ?
                    <>
                      {report.hot?.map((meal, mealIdx) => (
                        <tr key={meal.id} className={mealIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {meal.name}
                            <span className="block text-xs font-normal text-gray-500">{meal.id}</span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{meal.item_code}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{meal.booking_count || 0}</td>
                        </tr>
                      ))}
                      <tr className={(report.hot.length || 0) % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Total</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">{report.hot.map(m => parseInt((m.booking_count || '0'))).reduce((acc, cur) => acc + cur) || 0}</td>
                      </tr>
                    </> :
                    <tr className="bg-white">
                      <td colSpan={3} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">No Meals Booked</td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
          {/* End Hot Meals */}
          {/* Cold Meals */}
          <h2 className="mt-6 text-xl font-bold font-cursive text-brand-green-dark text-left page-break-before">
            Cold Meals
          </h2>
          <div className="mt-2 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg print:rounded-none print:shadow-none print:border-none">
            <table className="min-w-full divide-y divide-gray-200 text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Code
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Booked
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  report.cold && report.cold.length > 0 ?
                    <>
                      {report.cold?.map((meal, mealIdx) => (
                        <tr key={meal.id} className={mealIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {meal.name}
                            <span className="block text-xs font-normal text-gray-500">{meal.id}</span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{meal.item_code}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{meal.booking_count || 0}</td>
                        </tr>
                      ))}
                      <tr className={(report.cold.length || 0) % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Total</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">{report.cold.map(m => parseInt((m.booking_count || '0'))).reduce((acc, cur) => acc + cur) || 0}</td>
                      </tr>
                    </> :
                    <tr className="bg-white">
                      <td colSpan={3} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">No Meals Booked</td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
          {/* End Cold Meals */}
        </div>
      </div>
    </div>
  )
}
